<template>
  <div class="full-width column" :class="containerClass">
    <q-skeleton
      v-for="n in lines"
      :key="n"
      :class="n > 1 && skeletonClass"
      :width="getSkeletonWidth(n)"
      height="1em"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TextSkeleton'
})
</script>

<script setup>
import { randomNumber } from 'src/utils'

const props = defineProps({
  lines: {
    type: Number,
    default: 3
  },
  lineSpacing: {
    type: String,
    default: 'sm',
    validator: val => ['xs', 'sm', 'md', 'lg', 'xl'].includes(val)
  },
  align: {
    type: String,
    default: 'left'
  }
})
/* const emit = */ defineEmits([])

const containerClass = computed(() => `items-${props.align}`)
const skeletonClass = computed(() => `q-mt-${props.lineSpacing}`)

const widths = new Map()
const getSkeletonWidth = (n) => {
  let width = widths.get(n)
  if (!width) {
    width = `${randomNumber(75, 90)}%`
    widths.set(n, width)
  }

  return width
}
</script>
