import { useQuasar } from 'quasar'
import { provide, computed, reactive } from 'vue'
import isEqual from 'lodash.isequal'
import { ConfirmationDialogExitConfirmation as confirmExit } from 'src/utils/dialogs'

/**
 * @param {string} dialogName
 * @param {Record<string, function>} emits
 */
export const useRouteDialogEmits = (dialogName, emits) => {
  for (const [key, value] of Object.entries(emits)) {
    provide(`${dialogName} - ${key}`, value)
  }
}

/**
 *
 * @param {object} emits
 * @param {function} emits.onDialogHide
 * @param {function} emits.onOKClick
 * @param {function} emits.onCancelClick
 * @param {function} [onClose]
 * @returns
 */
export const useDialog = ({ onDialogHide, onOKClick, onCancelClick }, onClose) => {
  const $q = useQuasar()

  const dialogProps = reactive({
    position: computed(() => $q.screen.gt.xs ? 'standard' : 'bottom'),
    noEscDismiss: !!onClose,
    noBackdropDismiss: !!onClose
  })

  const onCloseDialog = typeof onClose === 'function'
    ? () => onClose({ confirmExit, isEqual })
    : onCancelClick

  const dialogListeners = {
    hide: onDialogHide,
    shake: onCloseDialog
  }

  return {
    dialogProps,
    dialogListeners,
    onCloseDialog
  }
}
