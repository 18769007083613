import {
  addBoletosBuilder,
  addCompanyBuilder,
  addCouponBuilder,
  addPlansBuilder,
  attemptCancelAccountReceivableBuilder,
  attemptIssueAccountReceivableBuilder,
  companiesWithoutPlanBuilder,
  companyDocumentBuilder,
  companyHistoryBuilder,
  couponDoctorsBuilder,
  doctorInvoiceBuilder,
  editAccountReceivableBuilder,
  editBoletosBuilder,
  editCompanyBuilder,
  editCouponBuilder,
  editDoctorBuilder,
  editPlanBuilder,
  historyAccountReceivableBuilder,
  infoAccountReceivableBuilder,
  newAccountReceivableBuilder,
  openDoctorInviteBuilder,
  planDoctorsBuilder,
  planHistoryBuilder,
  sendInvoiceEmailBuilder,
  sortActivePlansBuilder,
  companyRFactorHistoryBuilder
} from './dialogRouterBuilders'

export const dashboardNewUsersLeads = [
]

export const dashboardNewUsersLeadsSimulations = [
]

export const dashboardNewUsersIndications = [
]

export const dashboardNewUsersLeadsAccreditation = [
]

export const dashboardNewUsersRegisters = [
  editCompanyBuilder('Dashboard.NewUsers.Registers.Edit', 'empresa/:companyId/editar'),
  companyDocumentBuilder('Dashboard.NewUsers.Registers.CompanyFiles', 'empresa/:companyId/arquivos')
]

export const dashboardNewUsersOnboarding = [
  editCompanyBuilder('Dashboard.NewUsers.Onboarding.EditCompany', 'empresa/:companyId/editar'),
  editDoctorBuilder('Dashboard.NewUsers.Onboarding.EditDoctor', 'usuario/:userId/editar'),
  companyDocumentBuilder('Dashboard.NewUsers.Onboarding.CompanyFiles', 'empresa/:companyId/arquivos')
]

export const dashboardNewUsersLicense = [
  editCompanyBuilder('Dashboard.NewUsers.License.Edit', 'empresa/:companyId/editar'),
  companyDocumentBuilder('Dashboard.NewUsers.License.CompanyFiles', 'empresa/:companyId/arquivos')
]

export const dashboardDoctorsUsers = [
  editDoctorBuilder('Dashboard.Doctors.Users.EditDoctor', 'usuario/:userId/editar')
]

export const dashboardDoctorsDoctorCertificates = [
]

export const dashboardDoctorsGeneral = [
  addCompanyBuilder('Dashboard.Doctors.List.Create', 'criar'),
  openDoctorInviteBuilder('Dashboard.Doctors.List.OpenInvite', 'convite/:inviteId'),
  editCompanyBuilder('Dashboard.Doctors.List.Edit', 'empresa/:companyId/editar'),
  editDoctorBuilder('Dashboard.Doctors.List.EditDoctor', 'usuario/:userId/editar'),
  companyHistoryBuilder('Dashboard.Doctors.List.CompanyHistory', 'empresa/:companyId/historico'),
  doctorInvoiceBuilder('Dashboard.Doctors.List.UserInvoices', 'empresa/:companyId/notas-fiscais'),
  companyDocumentBuilder('Dashboard.Doctors.List.CompanyFiles', 'empresa/:companyId/arquivos')
]

export const dashboardDoctorsCompany = [
  addCompanyBuilder('Dashboard.Doctors.Companies.Create', 'criar'),
  editCompanyBuilder('Dashboard.Doctors.Companies.Edit', 'empresa/:companyId/editar'),
  companyHistoryBuilder('Dashboard.Doctors.Companies.CompanyHistory', 'empresa/:companyId/historico'),
  doctorInvoiceBuilder('Dashboard.Doctors.Companies.UserInvoices', 'empresa/:companyId/notas-fiscais'),
  companyDocumentBuilder('Dashboard.Doctors.Companies.CompanyFiles', 'empresa/:companyId/arquivos')
]

export const dashboardDoctorsNaturalPeople = [
  addCompanyBuilder('Dashboard.Doctors.NaturalPeople.Create', 'criar'),
  editCompanyBuilder('Dashboard.Doctors.NaturalPeople.Edit', 'empresa/:companyId/editar'),
  editDoctorBuilder('Dashboard.Doctors.NaturalPeople.EditDoctor', 'usuario/:userId/editar'),
  companyDocumentBuilder('Dashboard.Doctors.NaturalPeople.CompanyFiles', 'empresa/:companyId/arquivos')
]

export const dashboardDoctorsCompanyCertificates = [
  editCompanyBuilder('Dashboard.Doctors.CompanyCertificates.Edit', 'empresa/:companyId/editar')
]

export const dashboardBillingAccountsReceivable = [
  newAccountReceivableBuilder('Dashboard.Billing.AccountsReceivable.NewInvoice', 'criar'),
  editAccountReceivableBuilder('Dashboard.Billing.AccountsReceivable.EditInvoice', 'nota/:accountId/editar'),
  historyAccountReceivableBuilder('Dashboard.Billing.AccountsReceivable.History', 'nota/:accountId/historico'),
  infoAccountReceivableBuilder('Dashboard.Billing.AccountsReceivable.Info', 'nota/:accountId/detalhes'),
  attemptCancelAccountReceivableBuilder('Dashboard.Billing.AccountsReceivable.AttemptCancel', 'nota/:accountId/cencelar'),
  attemptIssueAccountReceivableBuilder('Dashboard.Billing.AccountsReceivable.AttemptIssue', 'nota/:accountId/emitir'),
  sendInvoiceEmailBuilder('Dashboard.Billing.AccountsReceivable.SendInvoiceEmail', 'nota/:accountId/enviar-email')
]

export const dashboardBoletos = [
  addBoletosBuilder('Dashboard.Boletos.Create', 'criar'),
  editBoletosBuilder('Dashboard.Boletos.Edit', 'boleto/:boletoId/editar')
]

export const dashboardBillingClients = [
]

export const dashboardMonthlyProcession = [
]

export const dashboardMonthlyClosings = [
]

export const dashboardMonthlyCashBookClosings = [
]

export const dashboardMonthlyStatements = [
]

export const dashboardMonthlyObligations = [
  editCompanyBuilder('Dashboard.Monthly.MonthlyObligations.Edit', 'empresa/:companyId/editar')
]

export const dashboardMonthlyRFactor = [
  companyRFactorHistoryBuilder('Dashboard.Monthly.RFactor.History', 'historico/empresa/:companyId')
]

export const dashboardFinancialPlans = [
  addPlansBuilder('Dashboard.Financial.Plans.Create', 'criar'),
  sortActivePlansBuilder('Dashboard.Financial.Plans.SortPlans', 'ordenar-planos'),
  companiesWithoutPlanBuilder('Dashboard.Financial.Plans.CompaniesWithoutPlan', 'sem-plano'),
  editPlanBuilder('Dashboard.Financial.Plans.Edit', 'plano/:planId/editar'),
  planDoctorsBuilder('Dashboard.Financial.Plans.PlanDoctors', 'plano/:planId/medicos'),
  planHistoryBuilder('Dashboard.Financial.Plans.PlanHistory', 'plano/:planId/historico')
]

export const dashboardFinancialCoupons = [
  addCouponBuilder('Dashboard.Financial.Coupon.Create', 'criar'),
  editCouponBuilder('Dashboard.Financial.Coupon.Edit', 'cupom/:couponId/editar'),
  couponDoctorsBuilder('Dashboard.Financial.Coupon.CouponDoctors', 'cupom/:couponId/medicos')
]

export const dashboardCashBookGeneral = [
]

export const dashboardCashBookUpdate = [
]

export const dashboardIrpfGeneral = [
  editDoctorBuilder('Dashboard.Irpf.General.EditDoctor', 'usuario/:userId/editar'),
  companyDocumentBuilder('Dashboard.Irpf.General.CompanyFiles', 'irpf/:irpfId/arquivos'),
  addBoletosBuilder('Dashboard.Irpf.General.CreatePayment', 'criar')
]

export const dashboardIrpfSettings = [
]

export const dashboardCalendarBookings = [
]

export const dashboardCalendarCalendar = [
]

export const dashboardCalendarReports = [
]

export const dashboardDutyManagementGroups = [
]

export const dashboardDutyManagers = [
]

export const dashboardAdvancedAdmins = [
]

export const dashboardAdvancedDepartment = [
]

export const dashboardAdvancedTermsOfService = [
]

export const dashboardAdvancedNotifications = [
]

export const dashboardAdvancedNews = [
]

export const dashboardAdvancedCollege = [
]

export const dashboardAdvancedConfigurations = [
]

export const dashboardAdvancedLogs = [
]

export const appHome = [
  {
    path: 'solicitar-nf',
    name: 'App.Home.InvoiceRequest',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/DoctorInvoiceRequestDialog.vue'),
      dialogName: 'InvoiceRequest',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'enviar-extrato/ano/:year/mes/:month',
    name: 'App.Home.UploadStatement',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/home/StatementDialog.vue'),
      dialogName: 'Upload',
      dialogEmits: []
    }
  },
  {
    path: 'empresa/:companyId/enviar-inss',
    name: 'App.Home.Inss',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/home/InssPaycheckDialog.vue'),
      dialogName: 'Inss',
      dialogEmits: []
    }
  },
  {
    path: 'empresa/:companyId/enviar-arquivos-para-ir',
    name: 'App.Home.Ir',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/ir/AddIrFileDialog.vue'),
      dialogName: 'Ir',
      dialogEmits: []
    }
  },
  {
    path: 'cadastrar-tomador',
    name: 'App.Home.NewClient',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/clients/NewClientDialog.vue'),
      dialogName: 'NewClient',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'adicionar-plantao',
    name: 'App.Home.NewEvent',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'NewEvent',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'indicar-amigo',
    name: 'App.Home.NewIndication',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/financial/NewIndicationDialog.vue'),
      dialogName: 'NewIndication',
      dialogEmits: []
    }
  },
  {
    path: 'nota-fiscal/:accountReceivableId',
    name: 'App.Home.InvoiceDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceDetailsDialog.vue'),
      dialogName: 'InvoiceDetails',
      dialogEmits: []
    }
  },
  {
    path: 'evento/:eventId/editar',
    name: 'App.Home.Calendar.Edit',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'EditEvent',
      dialogEmits: ['onUpdate', 'onDelete']
    }
  },
  {
    path: 'boleto/:boletoId',
    name: 'App.Home.BoletoDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/PaymentDetailsDialog.vue'),
      dialogName: 'BoletoDetails',
      dialogEmits: []
    }
  },
  {
    path: 'boletos-atrasados',
    name: 'App.Home.LateBoletos',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/LateBoletosDialog.vue'),
      dialogName: 'LateBoletos',
      dialogEmits: ['onRequestSecondCopy']
    }
  }
]

export const appInvoices = [
  {
    path: 'criar',
    name: 'App.Invoices.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/DoctorInvoiceRequestDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'empresa/:companyId/detalhes/:solicitationId',
    name: 'App.Invoices.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceSolicitationDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: ['onUpdate']
    }
  },
  {
    path: 'upload/:invoiceId',
    name: 'App.Invoices.UploadNF',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceSolicitationStepsDialog.vue'),
      dialogName: 'UploadNF',
      dialogEmits: ['onUpdate']
    }
  }
]

export const appBoletos = [
  {
    path: 'empresa/:companyId/boleto/:boletoId/historico',
    name: 'App.Boletos.History',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/DoctorBoletoHistoryDialog.vue'),
      dialogName: 'History',
      dialogEmits: []
    }
  }
]

export const appClients = [
  {
    path: 'criar',
    name: 'App.Clients.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/clients/NewClientDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'detalhes/:clientId',
    name: 'App.Clients.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/clients/ClientDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: []
    }
  }
]

export const appCashBook = [
  {
    path: 'criar',
    name: 'App.CashBook.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/cashBook/AddCashBookDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onAdd']
    }
  },
  {
    path: 'detalhes/:cashBookId',
    name: 'App.CashBook.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/cashBook/CashBookDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: []
    }
  }
]

export const appReceipt = [
  {
    path: 'criar',
    name: 'App.Receipt.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/receipt/ReceiptCreateEditDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'assinatura',
    name: 'App.Receipt.Signature',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/receipt/ReceiptSignatureDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'detalhes/:receiptId',
    name: 'App.Receipt.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/receipt/ReceiptDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: []
    }
  }
]

export const appStatements = [
  {
    path: 'enviar/ano/:year/mes/:month',
    name: 'App.Statements.Upload',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/home/StatementDialog.vue'),
      dialogName: 'Upload',
      dialogEmits: []
    }
  }
]

export const appCalendar = [
  {
    path: 'criar',
    name: 'App.Calendar.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'editar/:eventId',
    name: 'App.Calendar.Edit',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'Edit',
      dialogEmits: ['onUpdate', 'onDelete']
    }
  }
]

export const appCashFlow = [
  {
    path: 'nota-fiscal/:accountReceivableId',
    name: 'App.CashFlow.InvoiceDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceDetailsDialog.vue'),
      dialogName: 'InvoiceDetails',
      dialogEmits: []
    }
  },
  {
    path: 'boleto/:boletoId',
    name: 'App.CashFlow.BoletoDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/PaymentDetailsDialog.vue'),
      dialogName: 'BoletoDetails',
      dialogEmits: []
    }
  }
]

export const appReports = [
  {
    path: 'solicitar-nf',
    name: 'App.Reports.InvoiceRequest',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/DoctorInvoiceRequestDialog.vue'),
      dialogName: 'InvoiceRequest',
      dialogEmits: ['onCreate']
    }
  }
]
