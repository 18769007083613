import { getBanks } from 'src/services/banks'
import { Notify } from 'quasar'

export async function loadBanks ({ state, commit }, { showNotify = true } = {}) {
  if (state.BanksLoading || state.BanksLoaded) return
  commit('setBanksLoading', true)

  try {
    const data = await getBanks()

    commit('setBanks', data)
    commit('setBanksLoaded', true)
  } catch (err) {
    if (showNotify && !Array.isArray(state.banks)) {
      Notify.create({
        type: 'alert',
        message: 'Não foi possível carregar a listagem de bancos.'
      })
    }
  } finally {
    commit('setBanksLoading', false)
  }
}
