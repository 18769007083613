import { BALANCE_TYPE } from 'src/constants/balance'

export function showBalanceValue (state) {
  return state.show_balance_value
}

export function getBalanceType (state) {
  return state.balance_type
}

export function getBalanceTypeSettings (state) {
  return BALANCE_TYPE[state.balance_type] ?? {}
}
