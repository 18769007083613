import { DEFAULT_ACTION_MODE, TABLE_DEFAULT_ACTION_MODE } from 'src/constants/responsiveTable'

export function isColumnHidden (state) {
  return (tableName, colName) => {
    return (state.hideFields[tableName] ?? []).includes(colName)
  }
}

export function columnsHidden (state) {
  return (tableName) => {
    return (state.hideFields[tableName] ?? [])
  }
}

export function getActionMode (state) {
  return (tableName) => {
    return state.actionMode[tableName] ?? TABLE_DEFAULT_ACTION_MODE[tableName] ?? state.defaultActionMode ?? DEFAULT_ACTION_MODE
  }
}

export function getDefaultActionMode (state) {
  return state.defaultActionMode ?? DEFAULT_ACTION_MODE
}
