import { ALL_INVOICES } from 'src/constants/balance'

export default function () {
  return {
    balance_type: ALL_INVOICES,
    only_paid_received: false,
    show_balance_value: false,
    only_authorized_invoices: false
  }
}
