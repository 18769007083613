export const PIX_TYPE_EMAIL = 'E-mail'
export const PIX_TYPE_CPF_CNPJ = 'CPF/CNPJ'
export const PIX_TYPE_PHONE = 'Telefone'
export const PIX_TYPE_RANDOM_KEY = 'Chave Aleatória'

export const PIX_TYPES = [
  PIX_TYPE_EMAIL,
  PIX_TYPE_CPF_CNPJ,
  PIX_TYPE_PHONE,
  PIX_TYPE_RANDOM_KEY
]

export const PIX_TYPE_RANDOM_KEY_MAX_LENGTH = 32
export const PIX_TYPE_RANDOM_KEY_REGEX = /^[0-9A-Fa-f]+$/
