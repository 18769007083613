import { api } from 'src/boot/axios'
import { Dialog, Notify } from 'quasar'
import VerifyEmailDialog from 'components/dialogs/auth/VerifyEmailDialog.vue'
import { objectToFormData } from 'src/utils'

export async function attemptLogin ({ commit }, payload) {
  const { data } = await api.post('login', payload, {
    customErrorHandlers: {
      401: (data, { config }) => {
        if (typeof data.message === 'string') {
          Notify.create({
            type: 'alert',
            message: data.message
          })
          if (data.message === 'Acesso não autorizado, e-mail não verificado.') {
            return new Promise((resolve, reject) => {
              Dialog.create({
                component: VerifyEmailDialog,
                componentProps: {
                  email: payload.email
                }
              })
                .onOk(async () => {
                  const res = await api(config)

                  resolve(res)
                })
                .onCancel(reject)
            })
          }
        }
      }
    }
  })
  localStorage.setItem('access_token', data.access_token)
  commit('setUser', data.user)

  return data
}

export async function connectMicrosoft ({ commit }, { params }) {
  if (params?.code) {
    await api.get('csrf-cookie')
    const { data } = await api.get('connect', {
      params
    })

    localStorage.setItem('access_token', data.access_token)
    commit('setUser', data.user)
    return data
  }
  return null
}

export async function logout ({ commit }) {
  const token = localStorage.getItem('access_token')

  if (token) {
    localStorage.removeItem('access_token')

    await api.post('logout', null, {
      ignoreErrorHandling: true,
      enableLoading: true,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .catch(console.error)
  }

  commit('setUser', null)
  commit('company/clearCompanies', null, { root: true })
}

export async function attemptLoginInvite ({ commit }, payload) {
  const { data } = await api.post('login/invite', objectToFormData(payload))

  localStorage.setItem('access_token', data.access_token)
  commit('setUser', data.user)

  return data
}

export async function loadUser ({ commit, getters }) {
  const { data } = await api.get('user', { enableLoading: true })

  commit('setUser', {
    ...getters.getUser,
    ...data
  })
}
