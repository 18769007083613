import { date } from 'quasar'
import { emailPattern, romanPattern } from 'src/constants'
import { checkEqualNumbers, isDevelopmentEnv } from './index'
import DOMPurify from 'dompurify'
import moment from 'moment'

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const requiredRule = (msg, options) => {
  const defaults = {
    allowZero: false,
    allowEmptyString: false,
    ignoreHtmlTags: false,
    isBoolean: false
  }
  const { allowZero, allowEmptyString, ignoreHtmlTags, isBoolean } = Object.assign(defaults, options)

  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'O campo acima é obrigatório.'

    if (typeof val === 'string') {
      if (ignoreHtmlTags) {
        const allowedTags = Array.isArray(ignoreHtmlTags) ? ignoreHtmlTags : []

        val = DOMPurify.sanitize(val, { ALLOWED_TAGS: allowedTags })
      }
      val = val.trim()
      return (allowEmptyString && val === '') || val.length > 0 || errorMsg
    } else if (typeof val === 'number') {
      return (allowZero && val === 0) || !!val || errorMsg
    } else if (Array.isArray(val)) {
      return val.length > 0 || errorMsg
    } else if (isBoolean === true) {
      return typeof val === 'boolean' || errorMsg
    }

    return !!val || errorMsg
  }
}

/**
 *
 * @param {function|boolean} condition
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const requiredIfRule = (condition, msg, options) => {
  return val => {
    const requiredFn = requiredRule(msg, options)

    const isRequired = typeof condition === 'function'
      ? condition(val)
      : !!condition

    return !isRequired || requiredFn(val)
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validEmailRule = (msg, options) => {
  const defaults = {
    required: true
  }
  const { required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor insira um e-mail válido.'

    return emailPattern.test(val) || errorMsg
  }
}

/**
 *
 * @param {any} refVal
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const sameValueRule = (refVal, msg, options) => {
  // Para mais informações, veja https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator#parameters
  const defaults = {
    locales: 'pt-BR',
    sensitivity: 'variant',
    localeCompare: false
  }
  const { locales, sensitivity, localeCompare } = Object.assign(defaults, options)

  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val, refVal) : msg
    errorMsg ??= 'Os valores não coincidem.'

    return localeCompare
      ? val.localeCompare(refVal, locales, { sensitivity }) === 0 || errorMsg
      : val === refVal || errorMsg
  }
}

/**
 *
 * @param {number} min
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const minValueRule = (min, msg, options) => {
  if (isNaN(min)) {
    console.error(min, 'O valor mínimo não é um número válido.')
    min >>= 0
  }

  const defaults = {
    formatter: (n) => n,
    exclusive: false
  }
  const { formatter, exclusive } = Object.assign(defaults, options)

  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val, min) : msg
    errorMsg ??= `Não é permitido valor inferior${exclusive ? ' ou igual' : ''} a ${formatter(min)}.`

    return (exclusive ? val > min : val >= min) || errorMsg
  }
}

/**
 *
 * @param {number} max
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const maxValueRule = (max, msg, options) => {
  if (isNaN(max)) {
    console.error(max, 'O valor máximo não é um número válido.')
    max >>= 0
  }

  const defaults = {
    formatter: n => n,
    exclusive: false
  }
  const { formatter, exclusive } = Object.assign(defaults, options)

  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val, max) : msg
    errorMsg ??= `Não é permitido valor superior${exclusive ? ' ou igual' : ''} a ${formatter(max)}.`

    return (exclusive ? val < max : val <= max) || errorMsg
  }
}

/**
 *
 * @param {number} min
 * @param {number} max
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const inBetweenValueRule = (min, max, msg, options) => {
  if (min > max) console.error(min, max, 'O valor mínimo é maior que o valor máximo.')

  const defaults = {
    formatter: n => n,
    minExclusive: false,
    maxExclusive: false,
    required: true
  }
  const { formatter, minExclusive, maxExclusive, required } = Object.assign(defaults, options)

  const minValueFn = minValueRule(min, null, { exclusive: minExclusive })
  const maxValueFn = maxValueRule(max, null, { exclusive: maxExclusive })

  return val => {
    if (!required && !val) return true

    const minVal = minExclusive ? min - 1 : min
    const maxVal = maxExclusive ? max - 1 : max

    let errorMsg = typeof msg === 'function' ? msg(val, minVal, maxVal) : msg
    errorMsg ??= `O valor deve ser entre ${formatter(minVal)} e ${formatter(maxVal)}.`

    return (minValueFn(val) === true && maxValueFn(val) === true) || errorMsg
  }
}

/**
 *
 * @param {number} min
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const minLengthRule = (min, msg, options) => {
  const defaults = {
    exclusive: false,
    trim: true,
    required: true
  }
  const { exclusive, trim, required } = Object.assign(defaults, options)

  const minValueFn = minValueRule(min, null, { exclusive })

  return val => {
    if (!required && !val) return true

    const minVal = exclusive ? min + 1 : min

    let errorMsg = typeof msg === 'function' ? msg(val, minVal) : msg
    errorMsg ??= Array.isArray(val)
      ? `Mínimo de ${minVal} itens.`
      : `Mínimo de ${minVal} caracteres.`

    if (typeof val !== 'string' && !Array.isArray(val)) {
      console.error(`${val} is neither a string nor an array`)
      val = ''
    }

    const v = Array.isArray(val)
      ? val
      : trim
        ? val.trim()
        : val

    return minValueFn(v.length) === true || errorMsg
  }
}

/**
 *
 * @param {number} max
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const maxLengthRule = (max, msg, options) => {
  const defaults = {
    exclusive: false,
    trim: true,
    required: true
  }
  const { exclusive, trim, required } = Object.assign(defaults, options)

  const maxValueFn = maxValueRule(max, null, { exclusive })

  return val => {
    if (!required && !val) return true

    const maxVal = exclusive ? max - 1 : max

    let errorMsg = typeof msg === 'function' ? msg(val, maxVal) : msg
    errorMsg ??= Array.isArray(val)
      ? `Máximo de ${maxVal} itens.`
      : `Máximo de ${maxVal} caracteres.`

    if (typeof val !== 'string' && !Array.isArray(val)) {
      console.error(`${val} is neither a string nor an array`)
      val = ''
    }

    const v = Array.isArray(val)
      ? val
      : trim
        ? val.trim()
        : val

    return maxValueFn(v.length) === true || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const exactLengthRule = (amount, msg, options) => {
  const defaults = {
    trim: true,
    required: true
  }
  const { trim, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val, amount) : msg
    errorMsg ??= `O campo deve conter ${amount} caracteres.`

    if (typeof val !== 'string') {
      console.error(`${val} is not a string`)
      val = ''
    }

    const v = trim ? val.trim() : val
    return v.length === amount || errorMsg
  }
}

export const validRegexRule = (regex, msg) => {
  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'O campo não contém um formato válido.'

    if (typeof val !== 'string') {
      console.error(`${val} is not a string`)
      val = ''
    }

    return regex.test(val) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const notNegativeRule = (msg) => {
  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'O valor não pode ser negativo.'

    return val >= 0 || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const notPositiveRule = (msg) => {
  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'O valor não pode ser positivo.'

    return val <= 0 || errorMsg
  }
}

/**
 *
 * @param {number} min
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const minAgeRule = (min, msg, options) => {
  const defaults = {
    dateMask: 'YYYY-MM-DD',
    formatter: (d) => d,
    required: true
  }
  const { dateMask, formatter, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val, min) : msg
    errorMsg ??= `É necessário ter ${min} anos ou mais.`

    val = formatter(val)
    return date.subtractFromDate(new Date(), { years: min }) > date.extractDate(val, dateMask) || errorMsg
  }
}

/**
 *
 * @param {number} max
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const maxAgeRule = (max, msg, options) => {
  const defaults = {
    dateMask: 'YYYY-MM-DD HH:mm:mm',
    formatter: (d) => d,
    required: true
  }
  const { dateMask, formatter, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val, max) : msg
    errorMsg ??= `É necessário ter ${max} anos ou menos.`

    val = formatter(val)
    return date.addToDate(
      date.subtractFromDate(new Date(), { year: max + 1 }), { days: 1 }
    ) < date.extractDate(`${val} 23:59:59`, dateMask) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const validPhoneRule = (msg, options) => {
  const defaults = {
    required: true
  }
  const { required } = Object.assign(defaults, options)

  const minLengthFn = minLengthRule(10)
  const maxLengthFn = maxLengthRule(11)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor insira um telefone válido.'

    return (minLengthFn(val) === true && maxLengthFn(val) === true) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validNameRule = (msg, options) => {
  const defaults = {
    skipDev: false,
    required: true
  }
  const { skipDev, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true
    if (skipDev && isDevelopmentEnv()) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg

    if (invalidCharactersRule(['/', '\\'])(val) !== true) {
      errorMsg ??= 'Por favor, digite um nome válido'
      return errorMsg
    }

    errorMsg ??= 'Por favor, digite nome e sobrenome.'

    const name = val.trim()

    // Partes separadas por espaço ou por um hífen
    const parts = name.split(/(\s|-)+/)
    if (parts.length < 2) return errorMsg

    parts.sort((a, b) => b.length - a.length)

    // Valida nomes com pelo menos 1 parte com 3 letras e 1 parte com 2 letras (Ex: Yam-ni, Dak-Ho)
    return (parts[0].length >= 3 && parts[1].length >= 2) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validCpfRule = (msg, options) => {
  const defaults = {
    skipDev: true,
    required: true
  }
  const { skipDev, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true
    if (skipDev && isDevelopmentEnv()) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira um CPF válido'

    let sum = 0, mod

    if (typeof val !== 'string' || val.trim().length !== 11 || checkEqualNumbers(val)) return errorMsg

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(val.substring(i - 1, i)) * (11 - i)
    }
    mod = (sum * 10) % 11

    if (mod === 10 || mod === 11) mod = 0

    if (mod !== parseInt(val.substring(9, 10))) return errorMsg

    sum = 0
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(val.substring(i - 1, i)) * (12 - i)
    }
    mod = (sum * 10) % 11

    if (mod === 10 || mod === 11) mod = 0
    if (mod !== parseInt(val.substring(10, 11))) return errorMsg

    return true
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validCnpjRule = (msg, options) => {
  const defaults = {
    skipDev: true,
    required: true
  }
  const { skipDev, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true
    if (skipDev && isDevelopmentEnv()) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira um CNPJ válido'

    if (typeof val !== 'string' || val.trim().length !== 14 || checkEqualNumbers(val)) return errorMsg

    let size = val.length - 2
    let numbers = val.substring(0, size)
    const digitos = val.substring(size)
    let sum = 0
    let pos = size - 7

    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--
      if (pos < 2) pos = 9
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
    if (result !== parseInt(digitos.charAt(0))) return errorMsg

    size = size + 1
    numbers = val.substring(0, size)
    sum = 0
    pos = size - 7

    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--
      if (pos < 2) pos = 9
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
    if (result !== parseInt(digitos.charAt(1))) return errorMsg

    return true
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validPisRule = (msg, options) => {
  const defaults = {
    skipDev: true,
    required: true
  }
  const { skipDev, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true
    if (skipDev && isDevelopmentEnv()) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira um PIS válido'

    let sum = 0, mod
    const weights = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

    if (val.length !== 11 || checkEqualNumbers(val)) return errorMsg

    for (let i = 1; i <= 10; i++) {
      sum += parseInt(val.substring(i - 1, i)) * weights[i - 1]
    }
    mod = 11 - (sum % 11)

    if (mod === 10 || mod === 11) mod = 0

    if (mod !== parseInt(val.substring(10))) return errorMsg

    return true
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validCreditCardRule = (msg, options) => {
  const defaults = {
    skipDev: true,
    required: true
  }
  const { skipDev, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true
    if (skipDev && isDevelopmentEnv()) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira um número do cartão válido.'

    if (/[^0-9-\s]+/.test(val)) return errorMsg

    let nCheck = 0, bEven = false
    val = val.replace(/\D/g, '')

    for (let n = val.length - 1; n >= 0; n--) {
      const cDigit = val.charAt(n)
      let nDigit = parseInt(cDigit)

      if (bEven && (nDigit *= 2) > 9) nDigit -= 9
      nCheck += nDigit
      bEven = !bEven
    }
    return (nCheck % 10 === 0 && nCheck !== 0) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validDateRule = (msg, options) => {
  const defaults = {
    formatter: (d) => d,
    required: true
  }
  const { formatter, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira uma data válida.'

    val = formatter(val)
    return (/^\d{4}-\d{2}-\d{2}$/.test(val) && date.isValid(val)) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validCnhRule = (msg, options) => {
  const defaults = {
    required: true
  }
  const { required } = Object.assign(defaults, options)

  const exactLengthFn = exactLengthRule(11)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira uma CNH válida.'

    return exactLengthFn(val) === true || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validTimeRule = (msg, options) => {
  const defaults = {
    required: true,
    maxHours: 24
  }
  const { required, maxHours } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira um horário válido.'

    if (typeof val !== 'string' || /^\d{1,2}:\d{2}$/.exec(val) === null) return errorMsg

    const [hours, mins] = val.split(':').map((t) => parseInt(t))

    return (hours < maxHours && mins < 60) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const minDateDayRule = (min, msg, options) => {
  const defaults = {
    dateMask: 'YYYY-MM-DD',
    formatter: (d) => d
  }
  const { dateMask, formatter } = Object.assign(defaults, options)

  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val, min) : val
    errorMsg ??= `O dia deve ser posterior ou igual ao dia ${min}.`

    val = formatter(val)

    const day = date.extractDate(val, dateMask).getDate()
    return day >= min || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const maxDateDayRule = (max, msg, options) => {
  const defaults = {
    dateMask: 'YYYY-MM-DD',
    formatter: (d) => d
  }
  const { dateMask, formatter } = Object.assign(defaults, options)

  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val, max) : val
    errorMsg ??= `O dia deve ser posterior ou igual ao dia ${max}.`

    val = formatter(val)

    const day = date.extractDate(val, dateMask).getDate()
    return day <= max || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const noPastDateRule = (msg, options) => {
  const defaults = {
    appendTime: true,
    dateMask: 'YYYY-MM-DD 00:00:00',
    formatter: (d) => d,
    required: true,
    unit: 'days'
  }
  const { appendTime, dateMask, formatter, required, unit } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'A data não pode ser anterior.'

    val = formatter(val)

    if (appendTime) val += ' 00:00:00'
    const dateVal = date.extractDate(val, dateMask)

    const diff = date.getDateDiff(dateVal, new Date(), unit)

    return (typeof val === 'string' && diff >= 0) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const noTodayDateRule = (msg, options) => {
  const defaults = {
    appendTime: true,
    dateMask: 'YYYY-MM-DD 00:00:00',
    formatter: (d) => d,
    required: true
  }
  const { appendTime, dateMask, formatter, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'A data não pode ser igual a data atual.'

    val = formatter(val)

    if (appendTime) val += ' 00:00:00'
    const dateVal = date.extractDate(val, dateMask)

    const diff = date.getDateDiff(dateVal, new Date(), 'days')

    return (typeof val === 'string' && diff !== 0) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const noFutureDateRule = (msg, options) => {
  const defaults = {
    appendTime: true,
    dateMask: 'YYYY-MM-DD 00:00:00',
    formatter: (d) => d,
    required: true
  }
  const { appendTime, dateMask, formatter, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'A data não pode ser futura.'

    val = formatter(val)

    if (appendTime) val += ' 00:00:00'
    const dateVal = date.extractDate(val, dateMask)

    const diff = date.getDateDiff(dateVal, new Date(), 'days')

    return (typeof val === 'string' && diff <= 0) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const noWeekendDateRule = (msg, options) => {
  const defaults = {
    appendTime: true,
    dateMask: 'YYYY-MM-DD 00:00:00',
    formatter: (d) => d,
    required: true
  }
  const { appendTime, dateMask, formatter, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'A data não pode ser em um fim de semana.'

    val = formatter(val)

    if (appendTime) val += ' 00:00:00'
    const dateVal = date.extractDate(val, dateMask)

    return (typeof val === 'string' && ![0, 6].includes(dateVal.getDay())) || errorMsg
  }
}

/**
 *
 * @param {string} ref
 * @param {number} difference
 * @param {string} valTime
 * @param {string} refTime
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const datesDifferenceLowerThanRule = (ref, difference, valTime, refTime, msg, options) => {
  const defaults = {
    formatterVal: (d) => d,
    formatterRef: (d) => d,
    dateMask: 'YYYY-MM-DD HH:mm:00',
    required: true,
    exclusive: false,
    absolute: false,
    unit: 'days',
    diffFloatingValue: true
  }
  const { formatterVal, formatterRef, required, dateMask, exclusive, absolute, unit, diffFloatingValue } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val, ref, difference) : msg
    errorMsg ??= `A diferença entre as datas precisa ser menor ${!exclusive ? 'ou igual a' : 'que'} ${difference}.`

    if (typeof val !== 'string') return errorMsg

    const innerVal = formatterVal(val)
    const innerRef = formatterRef(ref)
    const innerValTime = valTime ?? '00:00'
    const innerRefTime = refTime ?? '00:00'

    const date1 = moment(date.extractDate(`${innerVal} ${innerValTime}:00`, dateMask))
    const date2 = moment(date.extractDate(`${innerRef} ${innerRefTime}:00`, dateMask))

    let datesDiff = date1.diff(date2, unit, diffFloatingValue)
    if (absolute) datesDiff = Math.abs(datesDiff)

    return (exclusive ? datesDiff < difference : datesDiff <= difference) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validRomanNumberRule = (msg, options) => {
  const defaults = {
    required: true
  }
  const { required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor insira um algarismo romano válido.'

    return romanPattern.test(val) || errorMsg
  }
}

/**
 *
 * @param {Array<any>} arr
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const notExistsInArrayRule = (arr, msg, options) => {
  const defaults = {
    callbackFn: (v, val) => v === val
  }
  const { callbackFn } = Object.assign(defaults, options)

  return val => {
    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'O elemento selecionado já existe.'

    return !arr.some(v => callbackFn(v, val)) || errorMsg
  }
}

/**
 *
 * @param {string} ref
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const greaterOrEqualThanDateRule = (ref, msg, options) => {
  const defaults = {
    formatterVal: (d) => d,
    formatterRef: (d) => d,
    dateMask: 'YYYY-MM-DD 00:00:00',
    required: true,
    exclusive: false
  }
  const { formatterVal, formatterRef, required, dateMask, exclusive } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= `A data 1 precisa ser maior ${!exclusive ? 'ou igual a' : 'que a'} data 2.`

    if (typeof val !== 'string') return errorMsg

    const innerVal = formatterVal(val)
    const innerRef = formatterRef(ref)

    const date1 = date.extractDate(`${innerVal} 00:00:00`, dateMask)
    const date2 = date.extractDate(`${innerRef} 00:00:00`, dateMask)

    return (exclusive ? date1 > date2 : date1 >= date2) || errorMsg
  }
}

/**
 *
 * @param {string} val
 * @param {Array<string>} charArr
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const invalidCharactersRule = (charArr, msg) => {
  return val => {
    if (typeof val !== 'string') {
      console.error(`${val} is not a string`)
      val = ''
    }

    const invalid = charArr.find(char => val.indexOf(char) > -1)

    let errorMsg = typeof msg === 'function' ? msg(val, invalid) : msg
    errorMsg ??= `Caractere inválido: ${invalid}`

    return typeof invalid === 'undefined' || errorMsg
  }
}

/**
 *
 * @param {string} char
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const invalidCharacterRule = (char, msg) => {
  return invalidCharactersRule([char], msg)
}

/**
 *
 * @param {Array<string>} charArr
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const validCharactersRule = (charArr, msg) => {
  return val => {
    if (typeof val !== 'string') {
      console.error(`${val} is not a string`)
      val = ''
    }

    const invalid = val.split('').find(char => !charArr.some(c => c === char))

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= msg ?? `Caractere inválido: ${invalid}`

    return typeof invalid === 'undefined' || errorMsg
  }
}

/**
 *
 * @param {string} char
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const validCharacterRule = (char, msg) => {
  return invalidCharactersRule([char], msg)
}

/**
 *
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const validBankDigitsRule = (msg) => {
  return validCharactersRule('0123456789-xX'.split(''), msg)
}

/**
 *
 * @param {object} options
 * @param {string|function} [msg]
 * @returns {validationFn = val => true | string}
 */
export const validUrlRule = (msg, options) => {
  const defaults = {
    url: '',
    required: true
  }
  const { url, required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'URL inválida'

    try {
      const newUrl = new URL(url, val)

      return !!newUrl ?? errorMsg
    } catch {
      return errorMsg
    }
  }
}

export const validUrl = (baseUrl, options, msg) => {
  const defaults = {
    url: ''
  }
  const { url } = Object.assign(defaults, options)
  msg ??= 'URL inválida'

  try {
    const newUrl = new URL(url, baseUrl)

    return !!newUrl ?? msg
  } catch {
    return msg
  }
}

export const validMonthRule = (msg, options) => {
  const defaults = {
    required: true
  }
  const { required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Mês inválido'

    const month = parseInt(val)

    return (!isNaN(month) && month >= 1 && month <= 12) || errorMsg
  }
}

export const validYearRule = (msg, options) => {
  const defaults = {
    required: true
  }
  const { required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Ano inválido'

    const year = parseInt(val)

    return (!isNaN(year) && year >= 1000) || errorMsg
  }
}

/**
 *
 * @param {string|function} [msg]
 * @param {object} [options]
 * @returns {validationFn = val => true | string}
 */
export const validHexRule = (msg, options) => {
  const defaults = {
    required: true
  }
  const { required } = Object.assign(defaults, options)

  return val => {
    if (!required && !val) return true

    let errorMsg = typeof msg === 'function' ? msg(val) : msg
    errorMsg ??= 'Por favor, insira um código hex válido.'

    const hexPattern = /^#[0-9a-fA-F]{3}([0-9a-fA-F]{3})?$/

    return hexPattern.test(val) || errorMsg
  }
}
