import { parseBoolean } from 'src/utils'

export const LAYOUT_LOCAL_STORAGE_KEY = 'appLayout'

export const LAYOUT_MODERN = 'modern'
export const LAYOUT_CLASSIC = 'classic'

export const isClassic = () => {
  return localStorage.getItem(LAYOUT_LOCAL_STORAGE_KEY) === LAYOUT_CLASSIC
}

export const isModern = () => {
  return localStorage.getItem(LAYOUT_LOCAL_STORAGE_KEY) === LAYOUT_MODERN
}

export const getLayout = () => {
  return localStorage.getItem(LAYOUT_LOCAL_STORAGE_KEY)
}

export const setLayout = (layout) => {
  localStorage.setItem(LAYOUT_LOCAL_STORAGE_KEY, layout)

  window.location.reload()
}

export const toggleLayout = () => {
  const newAppLayout = isModern()
    ? LAYOUT_CLASSIC
    : LAYOUT_MODERN

  setLayout(newAppLayout)
}

export const viewedModernLayoutAnnouncement = () => {
  return parseBoolean(localStorage.getItem('viewedModernLayoutAnnouncement')) === true
}

export const markModernLayoutAnnouncementAsViewed = () => {
  localStorage.setItem('viewedModernLayoutAnnouncement', 'true')
}
