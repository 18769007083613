export function setCompany (state, company) {
  state.company = company
}

export function setCompanyById (state, companyId) {
  state.company = state.companies.find(company => company.id === companyId)
}

export function setCompanies (state, companies) {
  state.companies = companies
}

export function clearCompanies (state) {
  state.company = null
  state.companies = []
  state.companiesLoading = false
}

export function setCompaniesLoading (state, payload) {
  state.companiesLoading = payload
}

export function markProtocolPopupAsRead (state, companyId) {
  const index = state.companies.findIndex(company => company.id === companyId)

  if (index > -1) {
    state.companies[index].show_protocol_popup = false
    state.companies = [...state.companies]
  }
}
