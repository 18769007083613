import { api } from 'src/boot/axios'

export const getGlobalConfigurations = async () => {
  const { data } = await api.get('configuration/show-all', {
    params: {
      new_format: true >>> 0
    }
  })

  return data
}
