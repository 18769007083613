export const companyDocumentBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/company/CompanyDocumentsDialog.vue'),
      dialogName: 'CompanyFiles',
      dialogEmits: ['']
    }
  }
}

export const editDoctorBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/doctors/EditDoctorDialog.vue'),
      dialogName: 'DoctorEdit',
      dialogEmits: ['onEdit', 'onDelete', 'onStatusChange', 'onCreateCompany']
    }
  }
}

export const editCompanyBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/company/EditCompanyDialog.vue'),
      dialogName: 'Edit',
      dialogEmits: ['onEdit', 'onMemberUpdate', 'onStatusChange', 'onDelete', 'onUpdate']
    }
  }
}

export const addCompanyBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/company/AddCompanyDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onInvite']
    }
  }
}

export const openDoctorInviteBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('components/dialogs/doctors/EditInviteDoctorDialog.vue'),
      dialogName: 'doctorInvite',
      dialogEmits: ['onUpdateInviteFn']
    }
  }
}

export const companyHistoryBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/company/CompanyHistoryDialog.vue'),
      dialogName: 'CompanyHistory',
      dialogEmits: ['onUpdateComments']
    }
  }
}

export const doctorInvoiceBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/doctors/DoctorInvoicesDialog.vue'),
      dialogName: 'UserInvoices',
      dialogEmits: ['']
    }
  }
}

export const newAccountReceivableBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/NewInvoiceDialog.vue'),
      dialogName: 'NewInvoice',
      dialogEmits: ['onCreate']
    }
  }
}

export const editAccountReceivableBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/EditInvoiceDialog.vue'),
      dialogName: 'EditInvoice',
      dialogEmits: ['onEdit', 'onDelete']
    }
  }
}

export const historyAccountReceivableBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceHistoryDialog.vue'),
      dialogName: 'AccountHistory',
      dialogEmits: ['']
    }
  }
}

export const infoAccountReceivableBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceInfoDialog.vue'),
      dialogName: 'AccountInfo',
      dialogEmits: ['onIgnoreCancelNfRequest', 'onInvoiceRefresh', 'onUpdateInvoiceData']
    }
  }
}

export const sendInvoiceEmailBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/SendInvoiceEmailDialog.vue'),
      dialogName: 'SendInvoiceEmail',
      dialogEmits: ['onIgnoreCancelNfRequest', 'onInvoiceRefresh', 'onUpdateInvoiceData']
    }
  }
}

export const attemptIssueAccountReceivableBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/AttemptIssueInvoiceDialog.vue'),
      dialogName: 'AccountAttemptIssue',
      dialogEmits: ['onCreate']
    }
  }
}

export const attemptCancelAccountReceivableBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/AttemptCancelInvoiceDialog.vue'),
      dialogName: 'AccountAttemptCancel',
      dialogEmits: ['onCancel']
    }
  }
}

export const addBoletosBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/NewPaymentDialog.vue'),
      dialogName: 'CreateBoleto',
      dialogEmits: ['onStore']
    }
  }
}

export const editBoletosBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/EditPaymentDialog.vue'),
      dialogName: 'EditBoleto',
      dialogEmits: ['onEdit']
    }
  }
}

export const companyRFactorHistoryBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/rFactor/CompanyRFactorDialog.vue'),
      dialogName: 'CompanyRFactorHistory',
      dialogEmits: ['onUpdate', 'onUpdateComments']
    }
  }
}

export const addPlansBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/plans/NewPlanDialog.vue'),
      dialogName: 'AddPlan',
      dialogEmits: []
    }
  }
}

export const editPlanBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/plans/EditPlanDialog.vue'),
      dialogName: 'EditPlan',
      dialogEmits: ['onEdit', 'onDelete']
    }
  }
}

export const sortActivePlansBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/plans/SortActivePlansDialog.vue'),
      dialogName: 'sortActivePlans',
      dialogEmits: ['']
    }
  }
}

export const companiesWithoutPlanBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/plans/CompaniesWithoutPlanDialog.vue'),
      dialogName: 'companiesWithoutPlan',
      dialogEmits: ['onAddPlan']
    }
  }
}

export const planDoctorsBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/plans/PlanDoctorsDialog.vue'),
      dialogName: 'planDoctors',
      dialogEmits: ['onAddDoctor', 'onDeleteDoctor']
    }
  }
}

export const planHistoryBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('components/dialogs/plans/PlanHistoryDialog.vue'),
      dialogName: 'planHistory',
      dialogEmits: []
    }
  }
}

export const addCouponBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/coupons/NewCouponDialog.vue'),
      dialogName: 'addCoupon',
      dialogEmits: []
    }
  }
}

export const editCouponBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/coupons/EditCouponDialog.vue'),
      dialogName: 'editCoupon',
      dialogEmits: ['onEdit', 'onDelete']
    }
  }
}

export const couponDoctorsBuilder = (name, path) => {
  return {
    path,
    name,
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/coupons/CouponDoctorsDialog.vue'),
      dialogName: 'couponDoctor',
      dialogEmits: ['onDelete']
    }
  }
}
