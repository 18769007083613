export const ACTION_MODE_EXPANDED = 'expanded'
export const ACTION_MODE_MENU = 'menu'
export const ACTION_MODE_ICONS = 'icons'

export const ACTION_MODE_OPTIONS = [
  { value: ACTION_MODE_EXPANDED, label: 'Detalhadas' },
  { value: ACTION_MODE_ICONS, label: 'Apenas Ícones' },
  { value: ACTION_MODE_MENU, label: 'Menu' }
]

export const DEFAULT_ACTION_MODE = ACTION_MODE_EXPANDED

export const TABLE_DEFAULT_ACTION_MODE = {
  // Boletos: ACTION_MODE_MENU
}
