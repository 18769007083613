import { date } from 'quasar'
import { api } from 'src/boot/axios'
import { FILE_URL_REFRESH_MINUTES } from 'src/constants/onedrive'
import { validUrlRule } from './rules'

/**
 * @param {Date|null} expiresAt
 * @returns {boolean}
 */
export const hasDownloadUrlExpired = (expiresAt) => {
  return !expiresAt || expiresAt.getTime() < new Date().getTime()
}

export const getNewExpirationDate = () => date.addToDate(new Date(), { minutes: FILE_URL_REFRESH_MINUTES })

/**
 * @param {string} id
 * @param {Ref<boolean>} [downloadLoading]
 * @returns {object}
 */
export const refreshDownloadUrl = async (id, downloadLoading, enableLoading = false) => {
  try {
    if (downloadLoading) downloadLoading.value = true

    if (validUrlRule()(id) === true) {
      return {
        url: id
      }
    } else {
      const { data } = await api.get(`file/${id}`, { enableLoading })

      return {
        url: data.url,
        downloadExpiresAt: date.addToDate(new Date(), { minutes: FILE_URL_REFRESH_MINUTES })
      }
    }
  } finally {
    if (downloadLoading) downloadLoading.value = false
  }
}

export const formatFilesObject = (files) => {
  return Object.entries(files)
    .flatMap(([type, _files]) => {
      return _files.map(file => ({
        ...file,
        type,
        downloadExpiresAt: file.downloadExpiresAt ?? getNewExpirationDate()
      }))
    })
}
