<template>
  <q-dialog
    ref="dialogRef"
    full-height
    full-width
    :maximized="maximized"
    v-bind="dialogProps"
    v-on="dialogListeners"
  >
    <q-card class="column">
      <dialog-header @close="onCloseDialog" title="Visualizar Imagem" class="col-auto" />
      <div style="height: 100%" class="col flex flex-center">
        <q-img fit="scale-down" style="width: 100%; max-height: 100%" :src="image" />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { useDialog } from 'src/composables/dialog'

export default defineComponent({
  name: 'ImageVisualizerDialog'
})
</script>

<script setup>

/* const props = */ defineProps({
  image: [String, File],
  maximized: Boolean
})

/* const emit = */ defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK: onOKClick, onDialogCancel: onCancelClick } = useDialogPluginComponent()

const { dialogProps, dialogListeners, onCloseDialog } = useDialog({ onDialogHide, onCancelClick, onOKClick })

</script>
