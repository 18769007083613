<template>
  <div class="row q-gutter-x-sm no-wrap justify-center">
    <q-field v-for="v, index in values" :key="index" data-cy="code-input">
      <template v-slot:control>
        <input
          @paste="evt => pasteCapture(evt, index)"
          class="q-field__input text-center text-weight-medium"
          :class="inputClass"
          style="font-size: 20px"
          :style="inputStyle"
          :autoFocus="autoFocus && index === autoFocusIndex"
          :data-id="index"
          :value="v"
          :ref="
            (el) => {
              if (el) inputs[index + 1] = el
            }
          "
          v-on:input="onValueChange"
          v-on:focus="onFocus"
          v-on:keydown="onKeyDown"
          :required="required"
          :disabled="disabled"
          maxlength="1"
          :data-cy="`code-input-${index + 1}`"
        />
      </template>
    </q-field>
  </div>
</template>

<script setup>
import { ref, toRef, onBeforeUpdate } from 'vue'
import { normalize } from 'src/utils/formatters'
import { clamp } from 'src/utils'

const props = defineProps({
  className: String,
  fields: {
    type: Number,
    default: 4
  },
  fieldWidth: {
    type: Number,
    default: 56
  },
  fieldHeight: {
    type: Number,
    default: 56
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: true
  },
  inputClass: [String, Object, Array],
  inputStyle: [String, Object]
})

const emit = defineEmits(['change', 'complete'])

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  delete: 46
}

const values = ref([])
const iRefs = ref([])
const inputs = ref([])
const fields = toRef(props, 'fields')
const autoFocusIndex = ref(0)
const autoFocus = true

const initVals = () => {
  let vals
  if (values.value && values.value.length) {
    vals = []
    for (let i = 0; i < fields.value; i++) {
      vals.push(values.value[i] || '')
    }
    autoFocusIndex.value =
      values.value.length >= fields.value ? 0 : values.value.length
  } else {
    vals = Array(fields.value).fill('')
  }
  iRefs.value = []
  for (let i = 0; i < fields.value; i++) {
    iRefs.value.push(i + 1)
  }
  values.value = vals
}

const onFocus = (e) => {
  e.target.select(e)
}

const onValueChange = (e) => {
  const index = parseInt(e.target.dataset.id)
  e.target.value = normalize(e.target.value)
  // this.handleKeys[index] = false;
  if (e.target.value === '' || !e.target.validity.valid) {
    return
  }
  let next
  const value = e.target.value
  values.value = Object.assign([], values.value)
  if (value.length > 1) {
    let nextIndex = value.length + index - 1
    if (nextIndex >= fields.value) {
      nextIndex = fields.value - 1
    }
    next = iRefs.value[nextIndex]
    const split = value.split('')
    split.forEach((item, i) => {
      const cursor = index + i
      if (cursor < fields.value) {
        values.value[cursor] = item
      }
    })
  } else {
    next = iRefs.value[index + 1]
    values.value[index] = value
  }
  if (next) {
    const element = inputs.value[next]
    element.focus()
    element.select()
  }
  triggerChange(values.value)
}

const onKeyDown = (e) => {
  const index = parseInt(e.target.dataset.id)
  const prevIndex = index - 1
  const nextIndex = index + 1
  const prev = iRefs.value[prevIndex]
  const next = iRefs.value[nextIndex]
  switch (e.keyCode) {
    case KEY_CODE.backspace: {
      e.preventDefault()
      const vals = [...values.value]
      if (values.value[index]) {
        vals[index] = ''
        values.value = vals
        triggerChange(vals)
      } else if (prev) {
        vals[prevIndex] = ''
        inputs.value[prev].focus()
        values.value = vals
        triggerChange(vals)
      }
      break
    }
    case KEY_CODE.delete: {
      e.preventDefault()

      if (index >= props.fields - 1) return

      const vals = [...values.value]
      vals.splice(index + 1, 1)
      vals.push('')

      values.value = vals
      triggerChange(values.value)
      break
    }
    case KEY_CODE.left:
      e.preventDefault()
      if (prev) {
        inputs.value[prev].focus()
      }
      break
    case KEY_CODE.right:
      e.preventDefault()
      if (next) {
        inputs.value[next].focus()
      }
      break
    case KEY_CODE.up:
    case KEY_CODE.down:
      e.preventDefault()
      break
    default:
      // this.handleKeys[index] = true
      break
  }
}

const triggerChange = (_values = values.value) => {
  const val = _values.join('')
  emit('change', val)
  if (val.length >= fields.value) {
    emit('complete', val)
  }
}

initVals()

const pasteCapture = (evt, index) => {
  evt.preventDefault()

  if (evt.clipboardData && evt.clipboardData.getData) {
    const text = evt.clipboardData.getData('text/plain').trim().toUpperCase()
    const n = clamp(text.length, index, props.fields)
    const newArr = values.value.slice()

    for (let i = 0, j = index; i < n && j < n; i++, j++) {
      newArr[j] = text.charAt(i)
    }

    values.value = newArr

    triggerChange(values.value)
  }
}

onBeforeUpdate(() => {
  inputs.value = []
})
</script>
