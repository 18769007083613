import { BALANCE_TYPE } from 'src/constants/balance'

export function setInitialSettings (state, payload) {
  const settingsKeys = ['only_paid_received', 'show_balance_value']

  settingsKeys.forEach(key => {
    if (state[key] === null || typeof state[key] === 'undefined') {
      state[key] = false
    }
  })
}

export function setBalanceType (state, payload) {
  state.balance_type = payload
  Object.assign(state, BALANCE_TYPE[payload])
}

export function toggleShowBalanceValue (state, payload) {
  state.show_balance_value = !state.show_balance_value
}
