import { Notify } from 'quasar'
import { getIbgeCities, getIbgeStates } from 'src/services/address'

export async function loadStates ({ commit, state }, { showNotify = true } = {}) {
  if (state.statesLoading || state.statesLoaded) return
  commit('setStatesLoading', true)

  try {
    const data = await getIbgeStates()

    commit('setStates', data)
    commit('setStatesLoaded', true)
  } catch (err) {
    if (showNotify && !Array.isArray(state.states)) {
      Notify.create({
        type: 'alert',
        message: 'Não foi possível carregar a listagem de estados.'
      })
    }
  } finally {
    commit('setStatesLoading', false)
  }
}

export async function loadCities ({ commit, getters, state }, { stateId, showNotify = true } = {}) {
  if (!stateId || getters.areStateCitiesLoading(stateId) || getters.areStateCitiesLoaded(stateId)) return
  commit('setStateCitiesLoading', { stateId, val: true })

  try {
    const data = await getIbgeCities(stateId)

    commit('setCitiesByStateId', { [stateId]: data })
    commit('setStateCitiesLoaded', { stateId, val: true })
  } catch (err) {
    if (showNotify && state.cities?.[stateId]) {
      Notify.create({
        type: 'alert',
        message: 'Não foi possível carregar a listagem de cidades.'
      })
    }
  } finally {
    commit('setStateCitiesLoading', { stateId, val: false })
  }
}
