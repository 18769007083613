<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" @shake="onClose" :position="$q.screen.xs ? 'bottom' : 'standard'" persistent>
    <q-card class="default-dialog">
      <dialog-header @close="onClose" title="Termos de Serviço" sticky />
      <q-card-section>
        <div v-if="mode === 'accept'" class="text-body2">
          Por favor, aceite os termos de serviço para prosseguir
        </div>
        <div v-if="termsOfService" class="text-caption">
          <span class="text-weight-medium">
            Última atualização:
          </span>
          {{ formattedDate }}
        </div>
      </q-card-section>
      <q-card-section v-if="termsLoading">
        <text-skeleton align="left" :lines="10" />
      </q-card-section>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <q-card-section v-else-if="termsOfService" v-html="termsOfService.content" />
      <q-card-section v-else class="text-center">
        Nenhum termo de serviço ativo no momento.
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          v-if="mode === 'accept'"
          @click="acceptTermsOfService"
          :loading="acceptLoading"
          label="Aceitar Termos de Serviço"
          padding="sm md"
          no-caps
          unelevated
          color="primary"
          text-color="dark"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useDialogPluginComponent, useQuasar } from 'quasar'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { formatDate } from 'src/utils/formatters'
import { api } from 'src/boot/axios'

export default defineComponent({
  name: 'TermsOfServiceDialog'
})
</script>

<script setup>
import ConfirmationDialog from './ConfirmationDialog.vue'
import TextSkeleton from 'src/components/common/skeletons/TextSkeleton.vue'

const props = defineProps({
  termsOfServiceId: [String, Number],
  mode: {
    type: String,
    validator: (val) => ['show', 'accept'].includes(val)
  }
})

/* const emits = */ defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK: onOKClick, onDialogCancel: onCancelClick } = useDialogPluginComponent()

const $q = useQuasar()
const $store = useStore()
const $router = useRouter()

const termsOfService = ref(null)
const termsLoading = ref(false)
const loadTermsOfService = async () => {
  try {
    termsLoading.value = true
    const { data } = props.termsOfServiceId
      ? await api.get(`term-of-service/${props.termsOfServiceId}`)
      : await api.get('term-of-service/active', {
        customErrorHandlers: {
          404: () => {}
        }
      })

    termsOfService.value = data
  } finally {
    termsLoading.value = false
  }
}

onMounted(async () => {
  await loadTermsOfService()
})

const acceptLoading = ref(false)
const acceptTermsOfService = async () => {
  try {
    acceptLoading.value = true

    await api.post('term-of-service/accept')

    $q.notify({
      type: 'positive',
      message: 'Termos de Serviço aceitos com sucesso.'
    })

    onOKClick()
  } finally {
    acceptLoading.value = false
  }
}

const formattedDate = computed(() => formatDate(termsOfService.value?.created_at, 'DD/MM/YYYY [às] HH:mm'))

const onClose = () => {
  if (props.mode === 'accept') {
    return $q.dialog({
      component: ConfirmationDialog,
      componentProps: {
        title: 'Recusar Termos de Serviço',
        message: 'Deseja recusar os Termos de Serviço? Você será desconectado e só poderá utilizar a aplicação após aceitar os termos.',
        persistent: true,
        okLabel: 'Recusar Termos de Serviço',
        cancelLabel: 'Voltar',
        reverse: true
      }
    })
      .onOk(async () => {
        onCancelClick()
        await $store.dispatch('auth/logout')
        await $router.push({ name: 'Login' })
      })
  } else {
    onCancelClick()
  }
}
</script>
