export function getBanks (state) {
  return state.banks
}

export function areBanksLoading (state) {
  return state.banksLoading
}

export function areBanksLoaded (state) {
  return state.banksLoaded
}
