import { api } from 'src/boot/axios'

export async function loadCompanies ({ commit, getters }, { companyId, setCompany = false } = {}) {
  try {
    commit('setCompaniesLoading', true)

    const { data } = await api.get('companies')

    commit('setCompanies', data)

    if (setCompany) {
      companyId ??= getters.getCompany?.id
      const currentCompany = data.find(company => company.id === companyId)

      commit('setCompany', currentCompany ?? data[0] ?? null)
    }
  } finally {
    commit('setCompaniesLoading', false)
  }
}

export async function setCompanyById ({ commit }, companyId) {
  const { data } = await api.get(`company/${companyId}`)

  commit('setCompany', data)

  return data
}
