<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" @shake="onClose" :position="$q.screen.xs ? 'bottom' : 'standard'" persistent>
    <q-card class="full-width">
      <q-form @submit.prevent="onSubmit">
        <dialog-header @close="onClose" title="Precisamos de você!" no-close-button sticky />
        <q-card-section>
          Para continuarmos com a abertura da sua Pessoa Jurídica, é necessário
          que você aprove a Minuta Contratual que elaboramos. Lembre-se de verificar
          os seguintes pontos:
        </q-card-section>
        <q-card-section class="q-py-none">
          <div class="row items-center q-col-gutter-sm">
            <p>
              <custom-emoji name="index-pointing-up" size="20px" />
              Confira se seus dados pessoais estão corretos;
            </p>
          </div>
          <div>
            <p>
              <custom-emoji name="victory-hand" size="20px" />
              Confira se todas as atividades que sua empresa poderá prestar foram descritas no item "Cláusula terceira".
            </p>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="text-weight-medium">
            Minuta Contratual
          </div>
          <div>
            Após baixar e ler a minuta, clique em "Aprovar" ou "Rejeitar" para que possamos
            prosseguir com o processo de abertura da sua Pessoa Jurídica.
          </div>
          <div class="q-mt-sm text-center">
            <q-btn
              :disable="downloadUrlLoading || !minutaUrl"
              label="Baixar"
              color="primary"
              padding="sm xl"
              icon="download"
              class="full-width"
              @click="downloadMinuta"
              outline
            >
              <q-inner-loading :showing="downloadUrlLoading" />
            </q-btn>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            @click="attemptRejectMinuta"
            label="Rejeitar"
            color="negative"
            padding="sm md"
            flat
          />
          <q-btn
            type="submit"
            label="Aprovar"
            color="positive"
            padding="sm md"
          >
            <q-inner-loading :showing="submitLoading" />
          </q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { api } from 'src/boot/axios'

import AttemptRejectMinutaDialog from './AttemptRejectMinutaDialog.vue'
import { useStore } from 'vuex'
import { downloadFile } from 'src/utils/files'

export default defineComponent({
  name: 'AttemptApproveMinutaDialog'
})
</script>

<script setup>
import { useDialogPluginComponent, useQuasar } from 'quasar'

/* const props = */ defineProps({})

/* const emit = */ defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK: onOKClick /*, onDialogCancel: onCancelClick */ } = useDialogPluginComponent()

const $q = useQuasar()
const $store = useStore()

const companyId = computed(() => $store.getters['company/getCompany']?.id)

const onClose = () => {
  // TODO
}

const minutaUrl = ref(null)
const downloadUrlLoading = ref(false)
const getMinutaDownloadUrl = async () => {
  try {
    downloadUrlLoading.value = true

    const { data } = await api.get(`company/${companyId.value}/register/draft/download-pdf`)

    minutaUrl.value = data
  } finally {
    downloadUrlLoading.value = false
  }
}

const downloadMinuta = () => {
  if ($q.platform.is.ios) {
    window.open(minutaUrl.value, '_blank')
  } else {
    downloadFile(minutaUrl.value)
  }
}

onMounted(async () => {
  await getMinutaDownloadUrl()
})

const attemptRejectMinuta = async () => {
  $q.dialog({
    component: AttemptRejectMinutaDialog,
    componentProps: {
      //
    }
  })
    .onOk(() => {
      onOKClick()
    })
}

const submitLoading = ref(false)
const onSubmit = async () => {
  try {
    submitLoading.value = true

    await api.patch(`company/${companyId.value}/register/draft/accept`)

    $q.notify({
      type: 'positive',
      message: 'Minuta aprovada com sucesso.'
    })

    onOKClick()
  } finally {
    submitLoading.value = false
  }
}
</script>
