import { Dialog } from 'quasar'
import ConfirmationDialog from 'src/components/dialogs/common/ConfirmationDialog.vue'
import TwoFactorDialog from 'src/components/dialogs/common/TwoFactorDialog.vue'
import { parseBoolean } from '.'

export const ConfirmationDialogExitConfirmation = ({
  title = 'Alterações não salvas',
  message = 'Existem alterações que não foram salvas, deseja sair mesmo assim?'
} = {}) => {
  return new Promise((resolve) => {
    Dialog.create({
      component: ConfirmationDialog,
      componentProps: {
        title,
        message,
        okLabel: 'Voltar à edição',
        cancelLabel: 'Sair',
        persistent: true,
        reverse: true
      }
    })
      .onOk(() => {
        resolve(false)
      })
      .onCancel(() => {
        resolve(true)
      })
  })
}

export const openTwoFactorDialog = async (props) => {
  if (parseBoolean(process.env.TWO_FACTOR_ENABLED, false)) {
    return new Promise((resolve, reject) => {
      Dialog.create({
        component: TwoFactorDialog,
        componentProps: props
      })
        .onOk(resolve)
        .onCancel(reject)
    })
  } else {
    return await props.onCodeSubmit(null)
  }
}
