export function setStates (state, payload) {
  state.states = payload ?? []
}

export function setCitiesByStateId (state, payload) {
  state.cities = {
    ...state.cities,
    ...payload
  }
}

export function setStatesLoading (state, payload) {
  state.statesLoading = payload
}

export function setStatesLoaded (state, payload) {
  state.statesLoaded = payload
}

export function setStateCitiesLoading (state, { stateId, val = false }) {
  state.citiesLoading[stateId] = val
}

export function setStateCitiesLoaded (state, { stateId, val = false }) {
  state.citiesLoaded[stateId] = val
}
