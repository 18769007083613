import { register } from 'register-service-worker'

import { Notify, EventBus } from 'quasar'

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

const bus = new EventBus()

window.bus = bus

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  ready (/* registration */) {
    // console.log('Service worker is active.')
  },

  registered (/* registration */) {
    // console.log('Service worker has been registered.')
  },

  cached (/* registration */) {
    // console.log('Content has been cached for offline use.')
  },

  updatefound (registration) {
    // eslint-disable-next-line no-console
    console.log('updatefound', registration)
    if (registration.active) bus.emit('sw:updatefound')
  },

  updated (registration) {
    // eslint-disable-next-line no-console
    console.log('updated', registration)
    bus.emit('sw:updated')
  },

  offline () {
    Notify.create({
      type: 'alert',
      message: 'Sem conexão com a internet.'
    })
  },

  error (err) {
    console.error('Error during service worker registration:', err)
  }
})
