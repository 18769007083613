<template>
  <q-dialog ref="dialogRef" v-on="dialogListeners" v-bind="dialogProps">
    <q-card class="full-width" >
      <dialog-header @close="onCancelClick" title="Autenticação de Dois Fatores" />
      <q-form @submit.prevent="onSubmit">
        <q-separator />
        <q-card-section v-if="!hasTwoFactor">
          <div class="text-center">
            <q-img
              :src="decodedQrCode"
              width="200px"
              height="200px"
            >
              <q-inner-loading :showing="qrCodeLoading" />
            </q-img>
          </div>
          <div class="q-mt-sm">
            Escaneie o código QR acima com um aplicativo de autenticação e preencha abaixo o código informado pelo aplicativo.
          </div>
          <div class="q-mt-sm">
            Recomendamos o uso do
            <a href="https://www.microsoft.com/pt-br/security/mobile-authenticator-app" target="_blank" rel="noopener noreferrer" class="text-bold text-accent">
              Microsoft Authenticator<q-icon name="open_in_new" size="1em" class="q-ml-xs" />
            </a>
            ou do
            <a href="https://support.google.com/accounts/answer/1066447?hl=pt-Br&co=GENIE.Platform%3DAndroid" target="_blank" rel="noopener noreferrer" class="text-bold text-accent">
              Google Authenticator<q-icon name="open_in_new" size="1em" class="q-ml-xs" />
            </a>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="text-bold text-body1">
            Insira o seu Código de Autenticação atual para
            {{ description }}
          </div>
          <code-input @change="val => key = val" :fields="CODE_LENGTH" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            type="a"
            :href="TWO_FACTOR_TUTORIAL_URL"
            target="_blank"
            label="Ajuda"
            color="primary"
            padding="sm md"
            outline
          />
          <q-space />
          <q-btn
            @click="onCancelClick"
            label="Fechar"
            color="accent"
            padding="sm md"
            flat
          />
          <q-btn
            type="submit"
            label="Autenticar"
            color="primary"
            text-color="dark"
            padding="sm md"
            :disable="disableSubmit || submitLoading"
          >
            <q-inner-loading :showing="submitLoading" />
          </q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent } from 'quasar'
import { api } from 'src/boot/axios'
import { useDialog } from 'src/composables/dialog'
import { defineComponent, onMounted, ref, shallowReactive, computed } from 'vue'
import { useStore } from 'vuex'
import { TWO_FACTOR_TUTORIAL_URL } from 'src/constants'

export default defineComponent({
  name: 'TwoFactorDialog'
})
</script>

<script setup>
import CodeInput from 'src/components/common/CodeInput.vue'

const CODE_LENGTH = 6

const props = defineProps({
  onCodeSubmit: Function,
  description: {
    type: String,
    default: 'prosseguir'
  }
})

/* const emit = */ defineEmits([...useDialogPluginComponent.emits])

const key = ref('')

const { dialogRef, onDialogHide, onDialogOK: onOKClick, onDialogCancel: onCancelClick } = useDialogPluginComponent()

const { dialogProps, dialogListeners } = shallowReactive(useDialog(
  { onDialogHide, onCancelClick, onOKClick },
  async ({ confirmExit, isEqual }) => {
    if (key.value && await confirmExit() === false) return
    onCancelClick()
  }
))

const $store = useStore()

const qrCode = ref(null)
const decodedQrCode = ref(null)
const qrCodeLoading = ref(false)
const loadQrCode = async () => {
  try {
    qrCodeLoading.value = true
    const { data } = await api.get('user/2fa/qr-code')

    qrCode.value = data
    decodedQrCode.value = `data:image/svg+xml;base64,${btoa(data)}`
  } finally {
    qrCodeLoading.value = false
  }
}

const hasTwoFactor = computed(() => $store.getters['auth/hasTwoFactor'])

onMounted(async () => {
  if (!hasTwoFactor.value) {
    await loadQrCode()
  }
})

const disableSubmit = computed(() => key.value.length !== CODE_LENGTH)
const submitLoading = ref(false)
const onSubmit = async () => {
  try {
    submitLoading.value = true
    if (!hasTwoFactor.value) {
      await api.post('user/2fa/activate', {
        '2fa_key': key.value
      })

      $store.commit('auth/setTwoFactor', true)
    }

    let data
    if (props.onCodeSubmit) {
      data = await props.onCodeSubmit(key.value)
    }

    onOKClick(data)
  } finally {
    submitLoading.value = false
  }
}
</script>
