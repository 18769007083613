import { ACTION_MODE_OPTIONS, DEFAULT_ACTION_MODE, TABLE_DEFAULT_ACTION_MODE } from 'src/constants/responsiveTable'

export function toggleColumn (state, { tableName, colName, value }) {
  const fields = state.hideFields[tableName] ?? []

  state.hideFields[tableName] = value
    ? fields.filter(columnName => columnName !== colName)
    : fields.concat(colName)
}

export function setActionMode (state, { tableName, actionMode }) {
  state.actionMode[tableName] = ACTION_MODE_OPTIONS.some(opt => opt.value === actionMode)
    ? actionMode
    : TABLE_DEFAULT_ACTION_MODE[tableName] ?? state.defaultActionMode ?? DEFAULT_ACTION_MODE
}

export function setDefaultActionMode (state, actionMode) {
  state.defaultActionMode = ACTION_MODE_OPTIONS.some(opt => opt.value === actionMode)
    ? actionMode
    : state.defaultActionMode ?? DEFAULT_ACTION_MODE
}
