export const ACTUAL_MONTH = 'actual_month'
export const ALL_INVOICES = 'all_invoices'
export const INCLUDE_ONLY_PAID_NF = 'include_only_paid_nf'

export const BALANCE_TYPE = {
  [ACTUAL_MONTH]: {
    only_authorized_invoices: true,
    only_paid_received: false
  },
  [ALL_INVOICES]: {
    only_paid_received: false
  },
  [INCLUDE_ONLY_PAID_NF]: {
    only_paid_received: true
  }
}
