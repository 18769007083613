import { Notify } from 'quasar'
import { getGlobalConfigurations } from 'src/services/configurations'

export async function loadConfigurations ({ commit, state }) {
  if (state.configurationsLoading) return
  commit('setConfigurationsLoaded', false)
  commit('setConfigurationsLoading', true)

  try {
    const data = await getGlobalConfigurations()

    commit('setConfigurations', data)
    commit('setConfigurationsLoaded', true)
  } catch (err) {
    commit('setConfigurationsLoaded', false)
    Notify.create({
      type: 'alert',
      message: 'Não foi possível realizar esta ação no momento. Tente novamente mais tarde.'
    })
  } finally {
    commit('setConfigurationsLoading', false)
  }
}
