import { api } from 'src/boot/axios'
import { Notify } from 'quasar'

export async function loadCountries ({ state, commit }, { showNotify = true } = {}) {
  if (state.countriesLoading || state.countriesLoaded) return
  commit('setCountriesLoading', true)

  try {
    const { data } = await api.get('countries')

    commit('setCountries', data)
    commit('setCountriesLoaded', true)
  } catch (err) {
    if (showNotify && !Array.isArray(state.countries)) {
      Notify.create({
        type: 'alert',
        message: 'Não foi possível carregar a listagem de países.'
      })
    }
  } finally {
    commit('setCountriesLoading', false)
  }
}
