export function setCountries (state, payload) {
  state.countries = payload ?? []
}

export function setCountriesLoading (state, payload) {
  state.countriesLoading = payload
}

export function setCountriesLoaded (state, payload) {
  state.setCountriesLoaded = payload
}
