<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" @shake="attemptDialogCancel" :position="$q.screen.xs ? 'bottom' : 'standard'" persistent>
    <q-card style="width: 600px; overflow-x: hidden">
      <q-form @submit.prevent="validateEmail">
        <dialog-header @close="attemptDialogCancel" title="E-mail não verificado" sticky />
        <q-card-section class="text-center">
          <p class="q-mb-sm">O e-mail <span class="text-bold">{{ email }}</span> ainda não foi verificado.</p>
          <p class="q-mb-none">Digite o código de verificação que foi enviado para o seu e-mail.</p>
          <p class="q-mt-sm q-mb-none text-caption">
            <template v-if="seconds === 0">
              Caso não tenha recebido o e-mail de confirmação,
              <span class="text-underline text-bold cursor-pointer" @click="resendVerification">clique aqui</span>
              para reenviar.
            </template>
            <template v-else>
              Você pode solicitar o reenvio do e-mail em <span class="text-bold">{{ seconds }}</span> segundos.
            </template>
          </p>
        </q-card-section>
        <q-card-section class="text-center q-pt-none q-mx-auto" style="width: 250px">
          <code-input @change="val => code = val" />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            @click="attemptDialogCancel"
            label="Fechar"
            color="accent"
            padding="sm md"
            flat
          />
          <q-btn
            type="submit"
            label="Confirmar"
            color="primary"
            text-color="dark"
            :disable="validateLoading"
            padding="sm md"
            no-caps
            data-cy="verify-submit"
          >
            <q-inner-loading :showing="validateLoading" />
          </q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent, ref, onUnmounted } from 'vue'
import { api } from 'src/boot/axios'
import { useDialogPluginComponent, useQuasar } from 'quasar'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'VerifyEmailDialog',
  setup () {
    //
  }
})
</script>

<script setup>
import CodeInput from 'src/components/common/CodeInput.vue'
import ConfirmationDialog from '../common/ConfirmationDialog.vue'

const props = defineProps({
  email: String
})

/* const emits = */ defineEmits([...useDialogPluginComponent.emits])

const $q = useQuasar()
const $route = useRoute()
const { dialogRef, onDialogHide, onDialogOK /*: onOKClick */, onDialogCancel: onCancelClick } = useDialogPluginComponent()

const code = ref('')

const attemptDialogCancel = () => {
  if ($route.name === 'Login') return onCancelClick()
  $q.dialog({
    component: ConfirmationDialog,
    componentProps: {
      title: 'Fechar Verificação',
      message: 'Tem certeza que deseja fechar a verificação de e-mail? Você poderá acessar o sistema apenas após a confirmação do seu e-mail.',
      okLabel: 'Ir para Login',
      cancelLabel: 'Cancelar',
      reverse: true
    }
  })
    .onOk(onCancelClick)
}

const validateLoading = ref(false)
const validateEmail = async () => {
  if (code.value.length < 4) {
    $q.notify({
      type: 'alert',
      message: 'Insira um código válido.'
    })

    return
  }

  try {
    validateLoading.value = true
    await api.get(`verify-email/${code.value}`)

    $q.notify({
      type: 'positive',
      message: 'E-mail verificado com sucesso.'
    })

    onDialogOK()
  } finally {
    validateLoading.value = false
  }
}

const seconds = ref(0)
let timer = null
const stopTimer = () => {
  clearInterval(timer)
  timer = null
}
const startTimer = () => {
  if (timer) stopTimer()
  seconds.value = 60

  timer = setInterval(() => {
    seconds.value--
    if (seconds.value <= 0) stopTimer()
  }, 1000)
}

onUnmounted(() => {
  stopTimer()
})

const resendedOk = ref(false)
const resendLoading = ref(false)
const resendVerification = async () => {
  try {
    resendLoading.value = true
    await api.post('resend-verification-email', { email: props.email })

    $q.notify({
      type: 'positive',
      message: 'E-mail reenviado com sucesso.'
    })

    resendedOk.value = true
  } finally {
    resendLoading.value = false

    if (resendedOk.value) {
      await startTimer()
      resendedOk.value = false
    }
  }
}
</script>
