<template>
  <q-dialog
    ref="dialogRef"
    full-height
    full-width
    :maximized="maximized"
    v-bind="dialogProps"
    v-on="dialogListeners"
  >
    <q-layout container>
      <q-page-container>
        <q-page class="column bg-dark">
          <dialog-header @close="onCloseDialog" :title="filename" sticky />
          <iframe :src="src" class="col rounded-borders q-ma-md"></iframe>
        </q-page>
      </q-page-container>
    </q-layout>
  </q-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { useDialogPluginComponent } from 'quasar'

import { useDialog } from 'src/composables/dialog'

export default defineComponent({
  name: 'PdfVisualizerDialog'
})
</script>

<script setup>

/* const props = */ defineProps({
  filename: {
    type: String,
    required: true
  },
  src: {
    type: String,
    required: true
  },
  maximized: Boolean
})

/* const emit = */ defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK: onOKClick, onDialogCancel: onCancelClick } = useDialogPluginComponent()

const { dialogProps, dialogListeners, onCloseDialog } = useDialog({ onDialogHide, onCancelClick, onOKClick })

</script>

<style lang="scss" scoped>
iframe {
  border: 1px solid var(--border_rgba);
}
</style>
