<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" :position="$q.screen.xs ? 'bottom' : 'standard'">
    <q-card class="full-width">
      <q-form @submit.prevent="onSubmit">
        <dialog-header @close="onCancelClick" title="Justificativa" sticky />
        <q-card-section>
          Poderia nos explicar o motivo da <span class="text-bold">rejeição</span>?
          <!-- Informe se algum dado está incorreto ou faltam informações... -->
          <div>
            <q-input
              v-model="justification"
              placeholder="Comente se há algum dado errado ou faltando..."
              :rules="rules.justification"
            />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            @click="onCancelClick"
            label="Fechar"
            color="accent"
            padding="sm md"
            flat
          />
          <q-btn
            type="submit"
            label="Enviar"
            :disable="submitLoading"
            color="negative"
            padding="sm md"
          >
            <q-inner-loading :showing="submitLoading" />
          </q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent, ref, reactive, computed } from 'vue'
import { useDialogPluginComponent, useQuasar } from 'quasar'
import { useStore } from 'vuex'
import { api } from 'src/boot/axios'

import { requiredRule } from 'src/utils/rules'

const useRules = (justification) => {
  return {
    justification: [requiredRule()]
  }
}

export default defineComponent({
  name: 'AttemptRejectMinutaDialog'
})
</script>

<script setup>

/* const props = */ defineProps({})

/* const emit = */ defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK: onOKClick, onDialogCancel: onCancelClick } = useDialogPluginComponent()

const $q = useQuasar()
const $store = useStore()

const companyId = computed(() => $store.getters['company/getCompany']?.id)

const justification = ref('')

const rules = reactive(useRules(justification))

const submitLoading = ref(false)
const onSubmit = async () => {
  try {
    submitLoading.value = true

    await api.patch(`company/${companyId.value}/register/draft/reject`, {
      comment: justification.value
    })

    $q.notify({
      type: 'positive',
      message: 'Analisaremos suas considerações e em breve retornamos.'
    })

    onOKClick()
  } finally {
    submitLoading.value = false
  }
}
</script>
