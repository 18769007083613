import jsonp from 'jsonp'
import { Notify, Dialog } from 'quasar'
import { api } from 'boot/axios'
import TermsOfServiceDialog from 'components/dialogs/common/TermsOfServiceDialog.vue'
import VerifyEmailDialog from 'components/dialogs/auth/VerifyEmailDialog.vue'
import AttemptApproveMinutaDialog from 'components/dialogs/registrations/AttemptApproveMinutaDialog.vue'

const promises = []
const resolveAllPromises = async () => {
  await Promise.allSettled(promises.map(async (v) => {
    api(v.config)
      .then(res => v.resolve(res))
      .catch(err => v.reject(err))
  }))

  promises.length = 0
}
const dialogs = [
  {
    message: 'Você não aceitou os termos de serviço',
    component: TermsOfServiceDialog,
    componentProps: { mode: 'accept' },
    open: false,
    onCancel: async ({ router, store }) => {
      await store.dispatch('auth/logout')
      await router.push({ name: 'Login' })
    }
  },
  {
    message: 'Seu E-mail não foi verificado.',
    component: VerifyEmailDialog,
    componentProps: ({ store }) => ({ email: store.getters['auth/getUser'].email }),
    open: false,
    onCancel: async ({ router, store }) => {
      await store.dispatch('auth/logout')
      await router.push({ name: 'Login' })
    }
  },
  {
    message: 'Você precisa aprovar ou rejeitar a minuta antes de continuar.',
    component: AttemptApproveMinutaDialog,
    open: false,
    onCancel: async ({ router, store }) => {
      await store.dispatch('auth/logout')
      await router.push({ name: 'Login' })
    }
  }
]

export const handleErrors = async (error, { router, store, customErrorHandlers }) => {
  if (!window.navigator?.onLine) {
    Notify.create({
      type: 'alert',
      message: 'Sem conexão com a internet.'
    })

    return Promise.reject(error)
  }

  if (!error.response || typeof error.response !== 'object') {
    Notify.create({
      type: 'alert',
      message: 'Problemas de conexão, por favor tente mais tarde.'
    })

    return Promise.reject(error)
  }

  const defaultErrorHandlers = {
    400: data => {
      if (typeof data?.message === 'string' && data.message.startsWith('RECAPTCHA_ERROR')) {
        Notify.create({
          type: 'alert',
          message: 'Não foi possível validar a integridade do seu acesso. Por favor, tente novamente.'
        })
        return
      }
      if (typeof data?.mensagem === 'string') {
        Notify.create({
          type: 'alert',
          message: data.mensagem
        })

        return
      }

      Notify.create({
        type: 'alert',
        message: 'Dados inválidos. Por favor, reveja seus dados e tente novamente.'
      })
    },
    401: async (data) => {
      Notify.create({
        type: 'alert',
        message: 'Sua sessão expirou, por favor faça novamente o login.',
        badgeClass: 'invisible'
      })

      await store.dispatch('auth/logout')
      await router.push({ name: 'Login' })
    },
    403: async data => {
      if (typeof data.message === 'string') {
        const dialog = dialogs.find(({ message }) => message === data.message)
        if (dialog) {
          if (dialog.open) {
            return new Promise((resolve, reject) => {
              promises.push({ resolve, reject, config: error.config })
            })
          } else {
            dialog.open = true

            return new Promise((resolve, reject) => {
              promises.push({ resolve, reject, config: error.config })

              Dialog.create({
                component: dialog.component,
                componentProps: typeof dialog.componentProps === 'function'
                  ? dialog.componentProps({ store })
                  : { ...dialog.componentProps }
              })
                .onOk(async () => {
                  await resolveAllPromises()
                  if (typeof dialog.onOk === 'function') dialog.onOk({ router, store })
                })
                .onCancel(async () => {
                  reject(error)
                  promises.length = 0
                  if (typeof dialog.onCancel === 'function') dialog.onCancel({ router, store })
                })
                .onDismiss(() => {
                  dialog.open = false
                  if (typeof dialog.onDismiss === 'function') dialog.onDismiss({ router, store })
                })
            })
          }
        }

        if (data.message === 'Você não tem permissão para acessar esse módulo') {
          Notify.create({
            type: 'alert',
            message: 'Você não possui permissões suficientes para realizar essa ação.'
          })
          await router.replace({ name: 'Login' })
          return
        }

        Notify.create({
          type: 'alert',
          message: data.message
        })

        return
      }
      Notify.create({
        type: 'alert',
        message: 'Você não possui permissões suficientes para realizar essa ação.'
      })
    },
    404: data => {
      Notify.create({
        type: 'alert',
        message: data.message ?? 'Recurso não encontrado.'
      })
    },
    422: data => {
      const error = data.errors ? Object.values(data.errors).flat().shift() : data.message
      Notify.create({
        type: 'alert',
        message: error ?? 'Dados inválidos.'
      })
    },
    429: (/* data */) => {
      Notify.create({
        type: 'warning',
        message: 'Você está realizando muitas ações em um curto período de tempo. Tente novamente mais tarde.'
      })
    },
    500: (/* data */) => {
      Notify.create({
        type: 'warning',
        message: store.getters['auth/isAdmin']
          ? 'Um erro interno ocorreu. Favor contatar a equipe de TI.'
          : 'Não foi possível realizar esta ação no momento. Tente novamente mais tarde.'
      })
    },
    503: async (/* data */) => {
      Notify.create({
        type: 'alert',
        message: 'Nós estamos em manutenção. Desculpe-nos pelo transtorno.'
      })
      await router.push({ name: 'Maintenance' })
    }
  }

  const errorHandler = { ...defaultErrorHandlers }

  Object.assign(errorHandler, customErrorHandlers)

  const { status } = error.response

  return (
    (
      errorHandler[status] &&
      (await errorHandler[status](error.response.data, {
        config: error.config,
        defaultHandler: (data = error.response.data) => defaultErrorHandlers[status](data)
      }))
    ) ||
    Promise.reject(error)
  )
}

/**
 * Simulates an API request
 * @param {number} page
 * @param {number} count
 * @param {array} items
 * @param {number} duration
 * @returns {Promise<array>}
 */
export const simulateRequest = (page = 1, count = 10, items = [], duration = 500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          total: items.length,
          data: items.slice((page - 1) * count, page * count)
        }
      })
    }, duration)
  })
}

/**
 * Wraps a jsonp request in a promise
 * @param {string} url
 * @param {Object} opts
 * @returns {Promise<Object>}
 */
export const fetchJsonp = (url, opts) => {
  return new Promise((resolve, reject) => {
    jsonp(url, opts, (err, data) => {
      if (err) return reject(err)

      return resolve(data)
    })
  })
}
