export function getStates (state) {
  return state.states
}

export function getCitiesByStateId (state) {
  return stateId => state.cities[stateId] ?? []
}

export function areStatesLoading (state) {
  return state.statesLoading
}

export function areStatesLoaded (state) {
  return state.statesLoaded && !state.statesLoading
}

export function areStateCitiesLoading (state) {
  return stateId => state.citiesLoading[stateId]
}

export function areStateCitiesLoaded (state) {
  return stateId => state.citiesLoaded[stateId]
}

export function getStateByUf (state) {
  return uf => getStates(state)?.find(st => st?.sigla === uf)
}

export function getStateById (state) {
  return stateId => getStates(state)?.find(st => st?.id === stateId)
}

export function getCityById (state) {
  return (stateId, cityId) => getCitiesByStateId(state)(stateId)
    .find(city => city?.id === cityId)
}

export function getCityByName (state) {
  return (stateId, name) => getCitiesByStateId(state)(stateId)
    .find(city => city?.nome === name)
}
