<template>
  <q-ajax-bar
    ref="barRef"
    position="top"
    color="primary"
    size="0.1rem"
    skip-hijack
  />
  <router-view />
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed, provide } from 'vue'
import { useStore } from 'vuex'
import { EventBus, useQuasar } from 'quasar'

export default {
  name: 'App'
}
</script>

<script setup>
import { setAddressbarColorFromRoute } from 'src/boot/addressbar-color'
import { useRouter } from 'vue-router'
import { isDevelopmentEnv, isHomologEnv } from './utils'

const $store = useStore()
const $router = useRouter()
const $q = useQuasar()

let barSubscriber
const barRef = ref(null)
let oldBarValue = false

onMounted(() => {
  barSubscriber = $store.subscribe(({ type, payload }) => {
    if (type !== 'globals/setLoading') return

    if (payload === true) {
      if (oldBarValue !== true) barRef.value.start()
    } else {
      barRef.value.stop()
    }
    oldBarValue = payload
  }, { prepend: true })
})

onUnmounted(() => {
  if (barSubscriber) barSubscriber()
})

const setTheme = (dark) => {
  const isDark = dark === true || dark === 'true'
  localStorage.setItem('darkMode', isDark)
  $q.dark.set(isDark)
}

onMounted(() => {
  const dark = localStorage.getItem('darkMode')
  setTheme(dark)
})

watch(() => $q.dark.isActive, val => {
  setAddressbarColorFromRoute($router.currentRoute.value)
})

// eslint-disable-next-line eqeqeq
const hideEnvironmentBanner = ref(localStorage.getItem('hideEnvironmentBanner') == 'true')
const showEnvironmentBanner = computed({
  get: () => !hideEnvironmentBanner.value && ((isDevelopmentEnv() && !process.env.DEV) || isHomologEnv()),
  set: (val) => {
    hideEnvironmentBanner.value = !val
    localStorage.setItem('hideEnvironmentBanner', !val)
  }
})

provide('showEnvironmentBanner', showEnvironmentBanner)

const showServiceWorkerUpdateBanner = ref(false)

if (!window.bus) window.bus = new EventBus()
window.bus?.on('sw:updatefound', () => {
  if (showServiceWorkerUpdateBanner.value === false) {
    showServiceWorkerUpdateBanner.value = 'updatefound'
  }
})
window.bus?.on('sw:updated', () => {
  showServiceWorkerUpdateBanner.value = 'updated'
})

provide('showServiceWorkerUpdateBanner', showServiceWorkerUpdateBanner)
</script>
