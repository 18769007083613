export const cpfMask = '###.###.###-##'
export const cnpjMask = '##.###.###/####-##'
export const cepMask = '#####-###'
export const percentageMask = '##,## %'
export const cnhMask = '###########'
export const rgMask = '##############'
export const nationalRegMask = '###########'
export const pisMask = '###.#####.##-#'
export const yearMask = '####'
export const addressNumberMask = '#####'
export const irpfMask = '##.##.##.##.##-##'
export const municipalTaxCodeMask = '#########'
export const pixRandomKeyMask = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'

export const phoneMask = (val) => {
  return val?.trim().length <= 10
    ? '(##) ####-#####'
    : '(##) #####-####'
}

const paddedCpfMask = cpfMask.padEnd(cnpjMask.length, '#')
export const cpfCnpjMask = (val) => {
  return val?.trim().length <= 11
    ? paddedCpfMask
    : cnpjMask
}

export const barCodeMask = (val) => {
  return val?.trim().length <= 47
    ? '#####.##### #####.###### #####.###### # ##############'
    : '###########-# ###########-# ###########-# ###########-#'
}
