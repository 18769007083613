export default function () {
  return {
    states: [],
    cities: {},
    statesLoading: false,
    statesLoaded: false,
    citiesLoading: {},
    citiesLoaded: {}
  }
}
