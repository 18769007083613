export function getCountries (state) {
  return state.countries
}

export function areCountriesLoading (state) {
  return state.countriesLoading
}

export function areCountriesLoaded (state) {
  return state.countriesLoaded
}

export function getCountryByCode (state) {
  return code => getCountries(state)?.find(country => country?.country_id === code)
}
