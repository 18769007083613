import { AddressbarColor, Dark } from 'quasar'
import { LAYOUT_CLASSIC, LAYOUT_MODERN, isModern } from 'src/utils/layout'

const defaultAddressbarColors = {
  light: '--q-dark',
  dark: '--q-dark'
}

export const setAddressbarColor = ({ light, dark }) => {
  const color = getComputedStyle(document.body)
    .getPropertyValue(Dark.isActive ? dark : light)

  AddressbarColor.set(color)
}

export const setAddressbarColorFromRoute = (route) => {
  try {
    let { meta: { addressbarColors } } = route.matched.find(_route => _route.meta.addressbarColors)

    if (!addressbarColors) throw new Error('Addressbar color not found for this route.')

    addressbarColors = addressbarColors[isModern() ? LAYOUT_MODERN : LAYOUT_CLASSIC] ?? addressbarColors

    setAddressbarColor(addressbarColors)
  } catch {
    setAddressbarColor(defaultAddressbarColors)
  }
}

export default () => {
  setAddressbarColor(defaultAddressbarColors)
}
