export function setConfigurations (state, payload) {
  state.configurations = payload ?? []
}

export function setConfigurationsLoading (state, payload) {
  state.configurationsLoading = payload
}

export function setConfigurationsLoaded (state, payload) {
  state.configurationsLoaded = payload
}
