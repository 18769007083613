import { statesOptions } from 'src/constants'
import { exportFile, scroll, Notify, date } from 'quasar'
import { getFileName } from './files'
import { api } from 'boot/axios'

const { getScrollTarget, setVerticalScrollPosition } = scroll

/**
 * Creates an array with numbers from 0 to n (n to m if m is a valid number) inclusive
 * @param {number} n couting start
 * @param {number} [m] couting end
 *
 * @returns {Array<number>} Array with numbers from n..m or 0..n
 */
export const getContiguousArray = (n, m) => {
  if (typeof m === 'undefined') [n, m] = [0, n]

  return Array.from({ length: m - n + 1 }, (_, i) => i + n)
}

/**
 * Escapes every html tag that may lead to an XSS attack
 *
 * @param {string} unsafe Unsafe string
 * @returns {string} Safe string
 */
export const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/\n/g, '<br>')
}

/**
 * Cleans every html tag from a string
 *
 * @param {string} val Unformatted string
 * @returns {string} Formatted string
 */
export const removeHtmlTags = (val) => {
  return val.replace(/<[^>]+>|<\/[^>]+>|<br>|\s|&nbsp;/gi, '')
}

const parseObjectToKeys = (obj, appendFn) => {
  const getPrefix = (prefix, key) =>
    prefix + (prefix ? '[' : '') + key + (prefix ? ']' : '')

  const fromArray = (_arr, prefix) => {
    for (let i = 0; i < _arr.length; i++) {
      const val = _arr[i]
      if (Array.isArray(val)) {
        fromArray(val, getPrefix(prefix, String(i)))
      } else if (val instanceof Object && !(val instanceof Blob)) {
        fromObject(val, getPrefix(prefix, String(i)))
      } else {
        appendFn(getPrefix(prefix, String(i)), val)
      }
    }
  }

  const fromObject = (_obj, prefix = '') => {
    for (const [key, value] of Object.entries(_obj)) {
      if (Array.isArray(value)) {
        fromArray(value, getPrefix(prefix, key))
      } else if (value instanceof Object && !(value instanceof Blob)) {
        fromObject(value, getPrefix(prefix, key))
      } else {
        appendFn(getPrefix(prefix, key), value)
      }
    }
  }

  fromObject(obj)
}

/**
 * Receives an object and returns a formdata object appending every object property on it
 *
 * @param {Object} obj Object
 * @returns {FormData}
 */
export const objectToFormData = (obj) => {
  const formData = new FormData()

  const appendValue = (prefix, value) => {
    if (typeof value === 'undefined') return
    else if (typeof value === 'boolean') value = value ? '1' : '0'
    else if (value === null) value = ''
    formData.append(
      prefix,
      value instanceof File || value instanceof Blob ? value : String(value)
    )
  }

  parseObjectToKeys(obj, appendValue)

  return formData
}

export const objectToQueryParams = (obj) => {
  const params = {}

  const appendValue = (prefix, value) => {
    if (typeof value === 'undefined') return
    else if (typeof value === 'boolean') value = value ? '1' : '0'
    else if (value === null) value = ''
    params[prefix] = value instanceof File || value instanceof Blob ? value : String(value)
  }

  parseObjectToKeys(obj, appendValue)

  return params
}

/**
 * @param {number} since First year
 * @returns {Array<number>} Array of years since the first year provided
 */
export const arrayOfYearsSince = (since) => {
  return Array.from(Array(new Date().getFullYear() - since + 1), (_, i) =>
    (i + since).toString()
  ).reverse()
}

/**
 * @param {string} name State name
 * @returns {string|null} State abbr
 */
export const getStateAbbr = (name) => {
  const state = statesOptions.find((state) => state.name === name)

  return state ? state.abbr : null
}

/**
 * @param {string} abbr State abbr
 * @returns {string|null} State anme
 */
export const getStateName = (abbr) => {
  const state = statesOptions.find((state) => state.abbr === abbr)

  return state ? state.name : null
}

/**
 * Returns a random number between 'n' and 'm'. If 'm' is not provided, then return a number between 0 and 'n'
 * @param {number} min n
 * @param {number} max m
 * @returns {number} Random number between n...m
 */
export const randomNumber = (min, max) => {
  if (isNaN(min)) return null
  if (typeof max !== 'undefined' && isNaN(max)) return null
  if (typeof max === 'undefined') [min, max] = [0, min]

  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 *
 * @param {string}      endpoint           Endpoint URL
 * @param {object|null} params             Endpoint params
 * @param {string}      [fileNameFallback] Fallback file name, if the endpoint doesn't return a name
 */
export const exportTable = async (endpoint, params, fileNameFallback) => {
  const { data, headers } = await api.get(endpoint, {
    responseType: 'blob',
    params
  })
  const fileName = getFileName(headers['content-disposition'], fileNameFallback)

  // Coloca a ação de baixar dentro de uma função
  const callback = () => {
    const exported = exportFile(fileName, data, headers['content-type'])

    if (exported !== true) {
      Notify.create({
        type: 'alert',
        message: 'Não foi possível baixar o arquivo'
      })

      throw exported
    }
  }

  try {
    // Chama a função para executar o download
    callback()
  } catch (err) {
    // O catch apenas exibe no console o erro para não deixar de retornar a callback caso falhe
    // eslint-disable-next-line no-console
    console.err(err)
  }
  // Retorna a função para poder dar a opção de baixar novamente
  return callback
}

/**
 *
 * @param {Element} ref      Vue ref
 * @param {number}  duration Duration (in milliseconds)
 */
export const scrollToElement = (ref, duration = 250, addToOffset = 0) => {
  const el = ref.$el || ref
  const target = getScrollTarget(el)
  const offset = el.offsetTop + addToOffset
  setVerticalScrollPosition(target, offset, duration)
}

/**
 * Checks if two dates are in the same day
 * @param {Date|string|number} date1 Date 1
 * @param {Date|string|number} date2 Date 2
 * @returns {boolean} Is in the same day
 */
export const isSameDay = (
  date1,
  date2
) => {
  date1 = new Date(date1)
  if (isNaN(date1.getTime())) throw new Error('Data 1 não é válida')
  date2 = new Date(date2)
  if (isNaN(date2.getTime())) throw new Error('Data 2 não é válida')

  const millisecondsInADay = 86400000 // 24h * 60min * 60sec * 1000ms

  const d1 = date1.getTime() - (date1.getTime() % millisecondsInADay)
  const d2 = date2.getTime() - (date2.getTime() % millisecondsInADay)

  return d1 === d2
}

/**
 * Array.findIndex returning multiple indexes
 * @param {Array}       arr      Array
 * @param {arrCallback} callback Callback function
 * @returns {Array<number>}
 */
export const arrayFindIndexMultiple = (
  arr,
  callback
) => {
  if (!Array.isArray(arr)) throw new TypeError('Invalid array')
  if (typeof callback !== 'function') throw new TypeError('Invalid callback')

  const indexes = []
  for (let i = 0; i < arr.length; i++) {
    if (callback(arr[i], i)) indexes.push(i)
  }

  return indexes
}

/**
 * Converts a time format string to minutes
 * @param {string} timestamp string in time format HH:mm
 * @returns {number} time in minutes
 */
export const timestampToMins = (timestamp) => {
  const [hours, mins] = timestamp.split(':').map((t) => parseInt(t))
  return hours * 60 + mins
}

/**
 * Converts a number of minutes into a time string HH:mm
 * @param {number} m minutes number
 * @returns {string} formatted string formatted HH:mm
 */
export const minsToTimestamp = (m) => {
  const mins = m % 60
  const hours = (m - mins) / 60

  return String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0')
}

/**
 * @param {string} base
 * @param {string} newString
 * @param {number} index
 * @returns {string}
 */
export const addString = (base, newString, index) => {
  return base.slice(0, index) + newString + base.slice(index)
}

/**
 * Returns a random element from the provided array
 * @param {Array<any>} items
 * @returns {any} Random array element
 */
export const randomElement = items => {
  if (!Array.isArray(items) || items.length === 0) return null

  return items[randomNumber(items.length - 1)]
}

export const clamp = (n, min, max) => {
  if (n > max) return max
  if (n < min) return min
  return n
}

export const isPlainObject = (obj) => obj && typeof obj === 'object' && Object.getPrototypeOf(obj) === Object.prototype

export const objectAssignNotNull = (target, ...sources) => {
  for (const source of sources) {
    for (const [key, value] of Object.entries(source)) {
      if (isPlainObject(value)) {
        if (!target[key]) target[key] = {}
        objectAssignNotNull(target[key], value)
      } else if ((value !== null && value !== undefined) || !Object.prototype.hasOwnProperty.call(target, key)) {
        Object.assign(target, { [key]: value })
      }
    }
  }

  return target
}

/**
 * @param {Array<unknown>} arr Source Array
 * @param {Function} callback
 * @returns {Object} Object with grouped arrays
 */
export const arrayGroupBy = (arr, callback) => {
  return arr.reduce(function (rv, x) {
    ;(rv[callback(x)] = rv[callback(x)] || []).push(x)
    return rv
  }, {})
}

export const getObjectProperties = (obj, ...keys) => {
  if (!obj || !(obj instanceof Object) || Array.isArray(obj)) { throw new Error('Invalid object') }
  const newObj = {}

  for (const key of keys) {
    if (Array.isArray(key)) {
      if (Object.prototype.hasOwnProperty.call(obj, key[0])) {
        newObj[key[1]] = typeof key[2] === 'function'
          ? key[2](obj[key[0]])
          : obj[key[0]]
      }
    } else {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        newObj[key] = obj[key]
      }
    }
  }

  return newObj
}

/**
 *
 * @param  {...object|Array<string>|string} classes
 * @returns {object} merged classes
 */
export const mergeClasses = (...classes) => {
  const obj = {}

  for (const _class of classes) {
    if (Array.isArray(_class)) {
      _class.forEach(c => {
        if (!c) return
        String(c).trim().split(/\s+/).forEach(_c => {
          obj[_c] = true
        })
      })
    } else if (typeof _class === 'object') {
      Object.assign(obj, _class)
    } else if (typeof _class === 'string') {
      _class.trim().split(/\s+/).forEach(c => {
        obj[c] = true
      })
    }
  }

  return obj
}

/**
 * Check if the APP_ENV is one of the envs provided
 * @param  {...string} envs
 * @returns {boolean}
 */
export const isAppEnv = (...envs) => envs.includes(process.env.APP_ENV)

/**
 * Checks if the app environment is development
 * @returns {boolean}
 */
export const isDevelopmentEnv = () => isAppEnv('local', 'dev')

/**
 * Checks if the app environment is homolog
 * @returns {boolean}
 */
export const isHomologEnv = () => isAppEnv('homolog')

/**
 * Checks if the app environment is production
 * @returns {boolean}
 */
export const isProductionEnv = () => isAppEnv('production')

/**
 * Checks if a string has only one character repeating itself
 * @param {string} str
 * @returns {boolean}
 */
export const checkEqualNumbers = (str) => {
  const letter = str.charAt(0)
  for (let i = 1; i < str.length; i++) {
    if (letter !== str.charAt(i)) {
      return false
    }
  }
  return true
}

const formatNumberWithPrecision = (method, n, precision) => {
  if (isNaN(precision) || precision <= 0) throw new Error('Precision expected to be higher than zero')

  const base = Math.pow(10, precision)

  return method(n * base) / base
}

export const roundWithPrecision = (n, precision) => {
  return formatNumberWithPrecision(Math.round, n, precision)
}

export const floorWithPrecision = (n, precision) => {
  return formatNumberWithPrecision(Math.floor, n, precision)
}

export const ceilWithPrecision = (n, precision) => {
  return formatNumberWithPrecision(Math.ceil, n, precision)
}

/**
 *
 * @param {Array<any>} arr
 * @param {any|function} el
 * @returns {Array<any>}
 */
export const spliceElFromArray = (arr, el, ...items) => {
  const index = typeof el === 'function'
    ? arr.findIndex(el)
    : arr.indexOf(el)

  if (index !== -1) return arr.splice(index, 1, ...items)[0]
}

/**
 * Search in array of objects a element[searchKey] equals searchValue and return the value of element[returnKey].
 * @param {array} [arr]
 * @param {any} searchValue
 * @param {string} searchKey
 * @param {string} returnKey
 */
export const arrayFindObjectItem = (arr, searchValue, searchKey = 'value', returnKey = 'label') => {
  const opt = arr.find((opt) => opt[searchKey] === searchValue)

  return opt ? opt[returnKey] : null
}

export const hasAction = (actionPermission, companyStatus) => {
  return actionPermission.includes(companyStatus)
}

/**
 *
 * @param {string} str
 * @param {string} needle
 * @returns {number}
 */
export const countOccurrencesInString = (str, needle) => {
  if (typeof str !== 'string') return 0
  if (typeof needle !== 'string') needle = String(needle)

  let count = 0
  for (let i = 0; i < str.length; i++) {
    if (str[i] === needle) count++
  }

  return count
}

/**
 * @param {any} val
 * @returns {boolean|null}
 */
export const parseBoolean = (val, defaultValue = null) => {
  if (val === true || val === 'true' || val === 1 || val === '1') return true
  if (val === false || val === 'false' || val === 0 || val === '0') return false

  return defaultValue
}

/**
 *
 * @param {any} val
 * @param {object} options
 * @returns {bool}
 */
export const isTruthyValue = (val, options) => {
  const defaults = {
    allowZero: false,
    allowEmptyString: false
  }
  const { allowZero, allowEmptyString } = Object.assign(defaults, options)

  if (allowZero && (typeof val === 'number' || typeof val === 'bigint') && !isNaN(val)) return true
  if (allowEmptyString && typeof val === 'string') return true

  return !!val
}

export const redirectToDatadog = ({ user_id: userId }) => {
  const url = new URL('rum/sessions', 'https://app.datadoghq.com')

  const queryParams = {
    type: 'session',
    'application.id': process.env.DATADOG_APP_ID,
    ...(userId && { 'usr.id': userId })
  }

  const now = new Date()

  const searchParams = {
    query: Object.entries(queryParams).map(([key, value]) => `@${key}:${value}`).join(' '),
    agg_m: 'count',
    agg_m_source: 'base',
    agg_t: 'count',
    cols: '',
    fromUser: 'true',
    track: 'rum',
    from_ts: date.subtractFromDate(now, { month: 1 }).getTime(),
    to_ts: now.getTime(),
    live: 'true'
  }

  Object.entries(searchParams).forEach(([key, value]) => {
    url.searchParams.append(key, value)
  })

  window.open(url, '_blank')
}

export const getBoolIconProps = (val) => {
  return val
    ? { name: 'check', color: 'positive' }
    : { name: 'close', color: 'red-6' }
}

export const mergeObjects = (obj1, obj2) => {
  for (const [key, value] of Object.entries(obj2)) {
    if (obj1[key]) {
      obj1[key] = value
    }
  }
}

export const arrayDiff = (arr1, ...arrs) => {
  const mergedArrs = arrs.flat()
  return arr1.filter(el => !mergedArrs.includes(el))
}

export const flattenObject = (obj, maxDepth = null, curDepth = 0, parent = '', res = {}) => {
  for (const key in obj) {
    const propName = parent ? parent + '.' + key : key

    if (typeof obj[key] === 'object' && (!maxDepth || curDepth < maxDepth)) {
      flattenObject(obj[key], maxDepth, curDepth + 1, propName, res)
    } else {
      res[propName] = obj[key]
    }
  }

  return res
}

export const arrayHasElements = (arr) => {
  return Array.isArray(arr) && arr.length > 0
}
