import { PARTNER, EMPLOYEE, PERMISSION_EDIT } from 'src/constants/users'
import { CONCLUDED_WIZARD, FINISHED_WIZARD, MIGRATED_COMPANY_TYPE, NATURAL_PERSON_TYPE, NEW_COMPANY_TYPE, REGISTER } from 'src/constants/signup'
import { REGISTER_CANCELED } from 'src/constants/registers'
import { ACTIVE, FROZEN } from 'src/constants/companies'
import { date } from 'quasar'
import { isPlainObject } from 'src/utils'

export function getCompany (state) {
  return state.company
}

export function getCompanies (state) {
  return state.companies ?? []
}

export function hasRole (state) {
  const company = getCompany(state)
  return (role, permission = PERMISSION_EDIT) => {
    return isPartner(state) || !!(
      isEmployee(state) &&
      (company.permissions ?? []).find(_role => _role.name === role && _role[permission])
    )
  }
}

export function isDemo (state) {
  return getCompany(state)?.enable_demo === true
}

export function isActive (state) {
  return getCompany(state)?.company_status === ACTIVE
}

export function isFrozen (state) {
  return getCompany(state)?.company_status === FROZEN
}

export function getCompaniesLoading (state) {
  return state.companiesLoading
}

export function getCompaniesLoaded (state) {
  return state.companiesLoading
}

export function enableToIssueInvoice (state) {
  return getCompany(state)?.nf_enable_status
}

export function isPartner (state) {
  return getCompany(state)?.user_type === PARTNER || getCompanies(state).length === 0
}

export function isEmployee (state) {
  return getCompany(state)?.user_type === EMPLOYEE
}

const checkOwnerWizardStep = (company, steps, notIncluded = false) => {
  let stepIncluded = steps.includes(company.wizard_step)
  if (notIncluded) stepIncluded = !stepIncluded

  return company.user_type === PARTNER &&
    company.is_owner === true &&
    stepIncluded
}

export const checkCompanyHasActiveWizard = company => {
  return checkOwnerWizardStep(company, [REGISTER, FINISHED_WIZARD, CONCLUDED_WIZARD], true)
}

export function hasActiveWizard (state) {
  const company = getCompany(state)

  return !company || checkCompanyHasActiveWizard(company)
}

export function hasAnyCompanyActiveWizard (state) {
  const companies = getCompanies(state)

  return companies.length === 0 || companies.some(company => checkCompanyHasActiveWizard(company))
}

export function isCompanyLead (state) {
  const company = getCompany(state)

  return !company || checkOwnerWizardStep(company, [REGISTER, FINISHED_WIZARD], true)
}

const checkCompanyIsWizardFinished = (company) => company.wizard_step === FINISHED_WIZARD

export function isWizardFinished (state) {
  const company = getCompany(state)

  return !!company && checkCompanyIsWizardFinished(company)
}

export function allCompaniesFinishedWizard (state) {
  const companies = getCompanies(state)

  return companies.every(company => checkCompanyIsWizardFinished(company))
}

const checkCompanyNotInWizard = (company) => [REGISTER, FINISHED_WIZARD].includes(company.wizard_step)

export function isNotInWizard (state) {
  const company = getCompany(state)

  return !!company && checkCompanyNotInWizard(company)
}

export function allCompaniesNotInWizard (state) {
  const companies = getCompanies(state)

  return companies.every(company => checkCompanyNotInWizard(company))
}

export function isWizardConcluded (state) {
  const company = getCompany(state)

  return !!company && company.wizard_step === CONCLUDED_WIZARD
}

export function isCompanyEnabled (state) {
  const company = getCompany(state)
  return !!company &&
    (isActive(state) || isFrozen(state)) &&
    [CONCLUDED_WIZARD, REGISTER, FINISHED_WIZARD].includes(company.wizard_step)
}

export function companyWithStatusHasTabPermission (state) {
  return (companyStatusOptions, needsCompleteWizard = false) => {
    const company = getCompany(state)

    if (needsCompleteWizard && !isWizardFinished(state)) {
      return false
    }

    return !!company && companyStatusOptions.includes(company.company_status)
  }
}

export function isCompanyInRegister (state) {
  const company = getCompany(state)

  return !!company && company.wizard_step === REGISTER
}

export function isRegisterCanceled (state) {
  const company = getCompany(state)

  return !!company && company.status === REGISTER_CANCELED
}

export function isCompanyActive (state) {
  const company = getCompany(state)

  return !!company &&
    isActive(state) &&
    company.wizard_step === FINISHED_WIZARD
}

export function isCompanyPF (state) {
  const company = getCompany(state)

  return !!company && company.type === NATURAL_PERSON_TYPE
}

export function isCompanyPJ (state) {
  const company = getCompany(state)

  return !!company && (company.type === NEW_COMPANY_TYPE || company.type === MIGRATED_COMPANY_TYPE)
}

export function isTypeNewCompany (state) {
  const company = getCompany(state)

  return !!company && company.type === NEW_COMPANY_TYPE
}

export function isTypeMigrateCompany (state) {
  const company = getCompany(state)

  return !!company && company.type === MIGRATED_COMPANY_TYPE
}

export function isTypeNaturalPerson (state) {
  const company = getCompany(state)

  return !!company && company.type === NATURAL_PERSON_TYPE
}

export function getCompanyById (state) {
  return companyId => getCompanies(state)?.find(company => company?.id === companyId) ?? null
}

export function getDueDate (state) {
  return getCompany(state)?.due_date
}

export function getDaysDifference (state) {
  return (dateParam) => {
    const dueDate = getDueDate(state)
    if (!dueDate) return null

    return date.getDateDiff(dateParam ?? new Date(), dueDate)
  }
}

export function isFreeTrial (state) {
  const company = getCompany(state)

  // TODO: Voltar para a condição após ajustes nas empresas sem plano
  return !company || (!isPlainObject(company.plan) && ![FINISHED_WIZARD, REGISTER].includes(company.wizard_step))
}

export function isConcierge (state) {
  const company = getCompany(state)

  return !!company && company.is_concierge
}
