import { api } from 'src/boot/axios'
import { STEPS_PRIORITY } from 'src/constants/signup'
import { sortByObjectDate, sortByPriorityObject } from 'src/utils/sort'

let loadCompanyPromise = null

export async function loadCompanies ({ commit, getters }, { companyId, setCompany = false } = {}) {
  try {
    commit('setCompaniesLoading', true)

    if (!loadCompanyPromise) {
      loadCompanyPromise = api.get('companies')
    }
    const { data } = await loadCompanyPromise

    commit('setCompanies', data)

    if (setCompany) {
      companyId ??= getters.getCompany?.id
      const currentCompany = data.find(company => company.id === companyId)

      if (currentCompany) {
        commit('setCompany', currentCompany)
      } else {
        const sortByWizardStep = sortByPriorityObject('wizard_step', STEPS_PRIORITY)
        const sortByCreatedAt = sortByObjectDate('created_at', 'asc')
        const prioritizedCompanies = data.slice().sort((a, b) => sortByWizardStep(a, b) || sortByCreatedAt(a, b))
        commit('setCompany', prioritizedCompanies[0] ?? null)
      }
    }
  } finally {
    loadCompanyPromise = null
    commit('setCompaniesLoading', false)
    commit('setCompaniesLoaded', true)
  }
}
