import { Dialog, exportFile } from 'quasar'
import ImageVisualizerDialog from 'src/components/dialogs/common/ImageVisualizerDialog.vue'
import PdfVisualizerDialog from 'src/components/dialogs/PdfVisualizerDialog.vue'
import { refreshDownloadUrl } from './onedrive'

export const sizeTypes = {
  KB: 1024,
  MB: 1048576,
  GB: 1073741824
}

export const regexSizeInString = /^(\d+)\s*(KB|MB|GB)$/

/**
 * Checks if a string is a valid number + unit of measurement string
 *
 * Examples:
 * - '1 mb' => valid
 * - '3 GB' => valid
 * - '1 m' => not valid
 * - '1' => not valid
 *
 * @returns {boolean} String is valid or not
 */
export const validateSizeString = (val) => {
  if (typeof val === 'string') {
    const match = regexSizeInString.exec(val)
    if (!match || match.length !== 3) return false

    return (
      !isNaN(parseInt(match[1])) &&
      Object.prototype.hasOwnProperty.call(sizeTypes, match[2])
    )
  }

  return typeof val === 'number' && (val === -1 || val > 0)
}

/**
 * Converts a string with its unit of measurement
 *
 * @param {number|string} size Size to be converted string (case-insensitive) or number in bytes
 *
 * Exemplos:
 * - getSizeInBytes(1024)
 * - getSizeInBytes('1 KB')
 * - getSizeInBytes('3 mb')
 * - getSizeInBytes('1024 Gb')
 *
 * @returns {number} Size in bytes
 */
export const getSizeInBytes = (size) => {
  if (typeof size === 'number') return size
  if (typeof size !== 'string') return null

  size = size.toUpperCase()

  const match = regexSizeInString.exec(size)
  if (match === null) return null

  const [, _size, type] = match

  return parseInt(_size) * sizeTypes[type]
}

/**
 * Converts a file upload into a base64 string
 *
 * @param {File} fileUpload File upload object
 *
 * @returns {string} Converted base64 string
 */
export const getFileBase64 = async (fileUpload) => {
  return new Promise((resolve, reject) => {
    if (!fileUpload) {
      return reject()
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileUpload)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

/**
 * Converts a base64 string into a Blob object
 * @param {string} fileBase64 Base64 string to be converted
 *
 * @returns {Blob} Converted Blob
 */
export const base64ToBlob = (fileBase64) => {
  return fetch(fileBase64).then(res => res.blob())
}

/**
 * Appends to DOM a link then clicks it to trigger download
 *
 * @param {string} url      File url
 * @param {string} fileName File default download name
 */
export const downloadFile = (url, fileName) => {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName || 'true'
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    document.body.removeChild(link)
  }, 10000)
}

/**
 * Chooses what action to take on a document click
 * - If it has path, trigger path download from a Url
 * - If it doesn't, export the File/Blob object then downloads it
 *
 * @param {string|File|Blob} document Document
 */
export const documentClick = (document) => {
  if (typeof document.url === 'string') {
    downloadFile(document.url)
  } else if (document.file instanceof File) {
    exportFile(document.file.name, document.file, document.file.type)
  }
}

/**
 * Returns the name of a file passing its response content disposition header, or returns a fallback name
 * @param {string} contentDisposition Content-Disposition responde header
 * @param {string} fileName Fallback file name
 * @returns {string} File name
 */
export const getFileName = (contentDisposition, fileName) => {
  if (typeof contentDisposition === 'string') {
    const fileNameMatch = /filename=(.+)/.exec(contentDisposition)

    if (fileNameMatch && fileNameMatch.length === 2) {
      fileName = fileNameMatch[1]
    }
  }
  return typeof fileName === 'string' ? fileName.replaceAll('"', '') : fileName
}

/**
 * Returns if a file type matches an accepted types string
 * @param {File}   file   File to be tested if matches
 * @param {string} accept Accepted file types (same as 'accept' attribute in input tags)
 */
export const checkFileType = (file, accept) => {
  if (accept === '*') return true
  if (typeof accept === 'string') accept = accept.split(',')

  return accept.some(acceptedType => {
    const parsed = acceptedType.trim().toLowerCase()

    // Se o tipo for .ext (Exemplo: .pdf)
    if (/^\.[-+.\w]+$/.exec(parsed)) {
      return file.name.toLowerCase().endsWith(parsed)
    }

    // Se o tipo for mimetype (Exemplo: application/pdf)
    if (/^\w+\/[-+.\w]+$/.exec(parsed)) {
      return file.type.toLowerCase() === parsed
    }

    // Se o tipo for mimetype/* (Exemplo: image/*)
    if (/^\w+\/\*$/.exec(parsed)) {
      return file.type.slice(0, file.type.indexOf('/')).toLowerCase() === parsed.slice(0, -2)
    }

    return false
  })
}

export const fetchedOnedriveFile = async (url, id) => {
  if (id && !url) {
    const oneDriveFile = await refreshDownloadUrl(id, null, true)
    url = oneDriveFile.url
  }

  return await fetch(url, {
    method: 'GET'
  }).then(res => res.blob()).then(blob => {
    return URL.createObjectURL(blob)
  })
}

export const openFileVisualizationDialog = async ({ id, filename, extension, url }, isOneDriveFile = false) => {
  if (isOneDriveFile) {
    url = await fetchedOnedriveFile(url, id)
  }

  if (extension === 'pdf' && navigator.pdfViewerEnabled) {
    Dialog.create({
      component: PdfVisualizerDialog,
      componentProps: {
        filename,
        src: url
      }
    })
  } else if (['png', 'jpg', 'jpeg'].includes(extension)) {
    Dialog.create({
      component: ImageVisualizerDialog,
      componentProps: {
        image: url
      }
    })
  } else {
    documentClick({ url })
  }
}
