import { api } from 'src/boot/axios'
import { clearDigits } from 'src/utils/formatters'

/**
 *
 * @param {string} cep
 */
export const getCepData = async (cep) => {
  cep = clearDigits(cep)
  if (cep.length !== 8) throw new Error('CEP inválido')

  const res = await fetch(
    `${process.env.VIACEP_API_URL}/${cep}/json/`
  )

  if (!res.ok) throw new Error('Ocorreu um erro ao buscar o CEP.')

  const data = await res.json()

  if (data.erro) throw new Error('Não foi encontrado nenhum dado para este CEP')

  return data
}

const ibgeFetchOptions = {
  method: 'GET',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json'
  }
}

/**
 * Fetchs brazilian states
 * @returns Promise<Array>
 */
export const getIbgeStates = async () => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.IBGE_API_URL}/localidades/estados?orderBy=nome`, ibgeFetchOptions)
      .then(res => res.json())
      .then(data => resolve(
        Array.from(data)
          .map(state => {
            return {
              ...state,
              id: state.id
            }
          })
      ))
      .catch(reject)
  })
}

/**
 * Fetchs cities by state id
 * @param {string|number} stateId
 * @returns Promise<Array>
 */
export const getIbgeCities = async (stateId) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.IBGE_API_URL}/localidades/estados/${stateId}/municipios?orderBy=nome`, ibgeFetchOptions)
      .then(res => res.json())
      .then(data => resolve(
        Array.from(data)
          .map(city => {
            return {
              ...city,
              id: city.id
            }
          })
      ))
      .catch(reject)
  })
}

/**
 *
 * @param {string|number} cityId
 * @returns Promise<Object>
 */
export const getIbgeCityByID = async (cityId) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.IBGE_API_URL}/localidades/municipios/${cityId}`, ibgeFetchOptions)
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(reject)
  })
}

/**
 *
 * @param {string|number} cityId
 * @returns Promise<Object>
 */
export const getViaCepAddresses = async (state, city, street) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VIACEP_API_URL}/${state}/${city}/${street}/json/`)
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(reject)
  })
}

/**
 *
 * @param {string} code
 * @param {string} [country] ISO 3166-1 alpha-2 country code (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)
 */
export const getZipCodeData = async (country, code) => {
  const { data } = await api.get('zip-code', {
    params: {
      country,
      code
    }
  })

  if (data.length === 0) {
    throw new Error(
      'Não foi encontrado nenhum dado para este código postal' +
      (country ? ` no país informado (${country}).` : '.')
    )
  }

  return data
}
