import { boot } from 'quasar/wrappers'
import axios from 'axios'
import { Loading } from 'quasar'
import { handleErrors } from 'src/utils/api'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({
  baseURL: new URL('api', process.env.API_URL).href,
  withCredentials: true,
  timeout: 180000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'Application/json',
    'Docstage-App-Version': process.env.VUE_APP_VERSION
  }
})

export default boot(({ app, router, store }) => {
  const globalLoadingRequests = new Set()

  api.interceptors.request.use((request) => {
    const token = localStorage.getItem('access_token')
    if (token) request.headers.Authorization = `Bearer ${token}`

    if (request.enableLoading) {
      Loading.show()
      globalLoadingRequests.add(request)
    }

    return request
  }, error => {
    Loading.hide()

    return Promise.reject(error)
  })

  api.interceptors.response.use((response) => {
    globalLoadingRequests.delete(response.config)
    if (globalLoadingRequests.size === 0) Loading.hide()

    return response
  }, (error) => {
    globalLoadingRequests.delete(error.config)
    if (globalLoadingRequests.size === 0) Loading.hide()

    if (error.config && !error.config.ignoreErrorHandling) {
      return handleErrors(error, { router, store, api, customErrorHandlers: error.config?.customErrorHandlers })
    }

    return Promise.reject(error)
  })

  // for use inside Vue files (Options API) through this.$api and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
})

export { api }
