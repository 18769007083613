export function setBanks (state, payload) {
  state.banks = payload ?? []
}

export function setBanksLoading (state, payload) {
  state.banksLoading = payload
}

export function setBanksLoaded (state, payload) {
  state.setBanksLoaded = payload
}
