const fetchOptions = {
  method: 'GET',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json'
  }
}

export function getBanks () {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.BRASIL_API_URL}/banks/v1`, fetchOptions)
      .then(res => res.json())
      .then(data => resolve(
        data.map(bank => ({
          ...bank,
          fullName: String(bank.fullName).replaceAll('"', '')
        }))
          .sort((a, b) => String(a.fullName).localeCompare(b.fullName, 'pt-BR', { sensitivity: 'base' }))
      ))
      .catch(reject)
  })
}
