export function getConfigurations (state) {
  return state.configurations
}

export function areConfigurationsLoading (state) {
  return state.configurationsLoading
}

export function areConfigurationsLoaded (state) {
  return state.configurationsLoaded && !state.configurationsLoading
}

export function getConfiguration (state) {
  return (field) => Array.isArray(state.configurations) &&
    state.configurations.find(configuration => configuration.field === field)
}
